import { ZfTabs } from "@ccx/zafire-react";
import { FC } from "react";
import { AccountViewEditComments } from "./AccountViewEditComments";
import { AccountViewEditAccountBalance } from "./AccountViewEditAccountBalance";
import { AccountViewEditAccountBalanceHistory } from "./AccountViewEditAccountBalanceHistory";
import { AccountViewEditLimits } from "./AccountViewEditLimits";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../store/store";
import { setSelectedTab } from "../../store/tab-slice";
import { AccountViewEditNostroLimits } from "./AccountViewEditNostroLimits";

export const AccountViewEditTabs: FC<{ nosId: number; zrNumber: number }> = (
  props
) => {
  //const [selectedTab, setSelectedTab] = useState("comments");
  //const selectedTab = useAppSelector((state) => state.authSlice.authTokens);

  const dispatch = useDispatch();
  const selectedTab = useAppSelector(
    (state) => state.tabStateSlice.selectedTab
  );

  function renderSelectedTab() {
    switch (selectedTab) {
      case "comments":
        return (
          <div data-testid="comments-tab">
            <AccountViewEditComments nosId={props.nosId} />
          </div>
        );
      case "kto-limits":
        return (
          <div data-testid="limits-tab">
            <AccountViewEditLimits nosId={props.nosId} />
          </div>
        );
      case "balance-crr":
        return (
          <div data-testid="account-balance-tab">
            <AccountViewEditAccountBalance nosId={props.nosId} />
          </div>
        );
      case "balance-hst":
        return (
          <div data-testid="account-balance-hist-tab">
            <AccountViewEditAccountBalanceHistory nosId={props.nosId} />
          </div>
        );
      case "nostro-limits":
        return (
          <div>
            <AccountViewEditNostroLimits
              nosId={props.nosId}
              data-testid="nostro-limits-tab"
            />
          </div>
        );
      default:
        return <div>To develop</div>;
    }
  }

  return (
    <ZfTabs
      size="medium"
      selectedTabId={selectedTab}
      data-testid="acc-view-edit-zftabs"
      tabs={[
        {
          id: "comments",
          title: "COMMENTS",
        },
        {
          id: "kto-limits",
          title: "ACCOUNT LIMITS",
        },
        {
          id: "balance-crr",
          title: "BALANCE/CRR",
        },
        {
          id: "balance-hst",
          title: "BALANCE/HIST",
        },
        {
          id: "nostro-limits",
          title: "NOSTRO LIMITS LOG",
        },
      ]}
      onTabSelect={(e) => dispatch(setSelectedTab(e.detail))}
    >
      {renderSelectedTab()}
    </ZfTabs>
  );
};
