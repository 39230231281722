import { ZfSearchField, ZfTable, ZfTextButton } from "@ccx/zafire-react";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../common/ZfTable/zftable-configuration";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import authorizedCall from "../../utils/authorizedCallUtils";
import { NostroControllerService } from "../../openapi";
import { useNavigate } from "react-router-dom";
import { StoreSlices, useAppSelector } from "../../store/store";
import { setSelectedNostroComment } from "../../store/select-item-slice-impl/selected-item-slice-nostro-comment";
import Spinner from "../common/Spinner";

export const AccountViewEditComments: FC<{ nosId: number }> = (props) => {
  const navigate = useNavigate();

  const [error, setError] = useState(false);

  const dataTableComments = useRef<HTMLZfTableElement>(null);

  const selected = useAppSelector(
    (state) => state.selectedNostroCommentSlice.selected
  );

  const [commFetching, setCommFetching] = useState<boolean>(true);

  const searchFilter = useRef<HTMLZfSearchFieldElement>(null);

  const dataTableCommentsApiCall = useCallback(
    async (url: any, config: any, params: any) => {
      setCommFetching(true);

      const sortArray: Array<string> =
        params.sort.length > 0
          ? [`${params.sort[0].field},${params.sort[0].dir}`]
          : [];

      let apiParams: Parameters<
        typeof NostroControllerService.getAllCommentsForNostro
      >[0] = {
        page: params.page,
        size: params.size,
        sort: sortArray,
        nostroPathId: props.nosId,
        search: searchFilter.current?.value,
      };

      try {
        try {
          const resp = await authorizedCall(
            NostroControllerService.getAllCommentsForNostro,
            apiParams,
            true
          );
          return resp;
        } catch (error) {}
      } finally {
      }
    },
    [props.nosId]
  );

  useEffect(() => {
    const defaultOptions = zfTableDefaultOptions();

    const TABLE_COLUMNS = [
      {
        formatter: "zfRadioRowSelection",
        hozAlign: "center",
        headerSort: false,
        width: 50,
      },
      //{ title: "ID", field: "id", maxWidth: 70 },
      { title: "Status", field: "nostroCommentStatusText", maxWidth: 140 },
      { title: "Username", field: "createUser", maxWidth: 100 },
      { title: "Date", field: "createTs", maxWidth: 120 },
      {
        title: "Show on report",
        field: "showOnReport",
        maxWidth: 100,
        formatter: (cell: any) => {
          return `<zf-checkbox checked=${cell.getValue()} disabled="true"></zf-checkbox>`;
        },
      },
      { title: "File link", field: "link", maxWidth: 320 },
      { title: "Comment", field: "comment" },
    ];

    const actionColumns: [] = [];

    setError(false);
    dataTableComments.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableCommentsApiCall,
      selectable: 1,
    };
  }, [props.nosId, dataTableCommentsApiCall]);

  const tableBuiltHandlerCallback = useCallback((event: any) => {
    tableBuiltHandler(
      event,
      setCommFetching,
      undefined,
      StoreSlices.selectedNostroCommentSlice,
      setSelectedNostroComment
    );
  }, []);

  function filteredSearch(event: any) {
    dataTableComments.current!.options = {
      ...dataTableComments.current!.options,
    };
  }

  /*console.log(
    "RENDER - AccountViewEditComments [commFetching:",
    commFetching,
    "]"
  );*/

  return (
    <>
      <div className="flex flex-row-reverse">
        <div className="basis-1/4 flex flex-row-reverse">
          <ZfTextButton
            hierarchy="primary"
            onClick={() => navigate(`/acc-comment-edit/${props.nosId}/new`)}
            size="small"
          >
            Create
          </ZfTextButton>
          <ZfTextButton
            disabled={selected === null}
            onClick={() =>
              navigate(`/acc-comment-edit/${props.nosId}/${selected?.id}`)
            }
            hierarchy="secondary"
            className="mr-1"
            data-testid="acc-view-edit-comm-edit-btn"
            size="small"
          >
            Edit
          </ZfTextButton>
        </div>
        <div className="basis-1/4 mr-12">
          <ZfSearchField
            placeholder="Search ..."
            onInputChange={(e) => {
              filteredSearch(e);
            }}
            ref={searchFilter}
          ></ZfSearchField>
        </div>
      </div>

      {error && <p className="error">Error in loading data</p>}

      <Spinner fetching={commFetching} />

      <ZfTable
        ref={dataTableComments}
        onTableBuilt={(event) => tableBuiltHandlerCallback(event)}
        //onTableRowSelectionChanged={rowSelectionChanged}
        className="mt-5"
        data-testid="acc-view-edit-comm-data-table"
        //onTableRowSelected={(event) => console.log("row click", event)}
        style={{
          pointerEvents: commFetching && "none",
          opacity: commFetching && 0.7,
        }}
      ></ZfTable>
    </>
  );
};
