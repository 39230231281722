import { EntityModelRelationshipManagerDto } from "../../openapi";
import { createSelectedItemSlice } from "../selected-item-slice";

// Create specific slices for different types of selected items
const SelectedViewRelManagerSlice =
  createSelectedItemSlice<EntityModelRelationshipManagerDto>(
    "selectedViewRelManager"
  );

export const { setSelectedItem: setSelectedViewRelManager } =
  SelectedViewRelManagerSlice.actions;

export default SelectedViewRelManagerSlice.reducer;
