import { useEffect, useRef, useState } from "react";
import authorizedCall from "../../utils/authorizedCallUtils";
import {
  ExportControllerService,
  JobControllerService,
  ReportResponse,
} from "../../openapi";
import ConfirmModal from "./ConfirmModal";
import { downloadCall } from "../../utils/downloadCallUtils";
import { store } from "../../store/store";

const dialogContents = {
  "import-account-balance": {
    title: "Confirmation",
    body: "Confirm import of account balance?",
    confirm: true,
  },
  "export-nos-vs-acc-bal": {
    title: "Confirmation",
    body: "Confirm export of Nostro vs Account Balance?",
    confirm: true,
  },
  "export-nos-lim": {
    title: "Confirmation",
    body: "Confirm export of Nostro Account Limits?",
    confirm: true,
  },
  "import-nostro": {
    title: "Confirmation",
    body: "Confirm update of all Nostro accounts?",
    confirm: true,
  },
  "archive-nostro": {
    title: "Confirmation",
    body: "Confirm archive of deleted Nostro accounts?",
    confirm: true,
  },
  "update-rel-manager": {
    title: "Confirmation",
    body: "Confirm update of Relationship managers?",
    confirm: true,
  },
  noAction: {
    title: "",
    body: "",
    confirm: false,
  },
};

const RenderModal = ({
  action,
  setOnClickAction,
  setComponentPanelFetching,
}: {
  action:
    | "import-account-balance"
    | "export-nos-vs-acc-bal"
    | "export-nos-lim"
    | "import-nostro"
    | "archive-nostro"
    | "update-rel-manager"
    | undefined;
  setOnClickAction: any;
  setComponentPanelFetching: any;
}) => {
  const [dialogContent, setDialogContent] = useState(
    dialogContents[!!action ? action : "noAction"]
  );
  const [afterClose, setAfterClose] = useState(false);

  const dialog = useRef<HTMLDialogElement>();

  useEffect(() => {
    if (action) {
      setDialogContent(dialogContents[action]);
    }
  }, [action]);

  useEffect(() => {
    if (dialogContent.body !== "" && !afterClose) {
      dialog.current && dialog.current.showModal();
    } else {
      setAfterClose(false);
    }
  }, [dialogContent, afterClose]);

  useEffect(() => {
    if (afterClose) {
      setDialogContent(dialogContents[!!action ? action : "noAction"]);
    }
  }, [afterClose, action]);

  function closeDialog() {
    console.log("closeDialog..");
    setOnClickAction(undefined);
    dialog.current && dialog.current.close();
    setAfterClose(true);
  }

  function importAccountBalance() {
    setComponentPanelFetching(true);
    authorizedCall(JobControllerService.restartJob, {
      jobName: "IMPORT_ACC_BAL",
    })
      .then(() => {
        setDialogContent(() => ({
          title: "Result",
          body: "Account balance import process has been taken over",
          confirm: false,
        }));
        dialog.current && dialog.current.showModal();
      })
      .finally(() => {
        setComponentPanelFetching(false);
      });
  }

  function exportNostroVsAccountBalance() {
    setComponentPanelFetching(true);
    authorizedCall(
      ExportControllerService.createExport,
      {
        requestBody: {
          type: "NOS_VS_ACC_BAL",
          timestamp: new Date().toISOString(),
        },
      },
      true
    )
      .then((resp: ReportResponse) => {
        console.log("response", resp);
        const token = store.getState().authSlice.authTokens?.accessToken;
        const id = resp?.reportId;
        console.log("reportId", id);

        downloadCall(id, token, id).then(() => {
          const textBody = "Export file " + id + " successfully downloaded";
          setDialogContent(() => ({
            title: "Result",
            body: textBody,
            confirm: false,
          }));
          dialog.current && dialog.current.showModal();
        });
      })
      .finally(() => {
        setComponentPanelFetching(false);
      });
  }

  function exportNostroLimits() {
    setComponentPanelFetching(true);
    authorizedCall(
      ExportControllerService.createExport,
      {
        requestBody: {
          type: "NOS_LIM_EXP",
          timestamp: new Date().toISOString(),
        },
      },
      true
    )
      .then((resp: ReportResponse) => {
        console.log("response", resp);
        const token = store.getState().authSlice.authTokens?.accessToken;
        const id = resp?.reportId;
        console.log("reportId", id);

        downloadCall(id, token, id).then(() => {
          const textBody = "Export file " + id + " successfully downloaded";
          setDialogContent(() => ({
            title: "Result",
            body: textBody,
            confirm: false,
          }));
        });
      })
      .finally(() => {
        setComponentPanelFetching(false);
      });
  }

  function updateAllAcc() {
    setComponentPanelFetching(true);
    authorizedCall(JobControllerService.restartJob, {
      jobName: "IMPORT_NOS",
    })
      .then(() => {
        setDialogContent(() => ({
          title: "Result",
          body: 'Update all nostro process has been taken over.\n After successfully importing all nostro, you will be redirected to "Nostro To Update" page.',
          confirm: false,
        }));
        dialog.current && dialog.current.showModal();
      })
      .finally(() => {
        setComponentPanelFetching(true);
      });
  }

  function archiveNos() {
    setComponentPanelFetching(true);
    authorizedCall(JobControllerService.restartJob, {
      jobName: "ARCHIVE_NOS",
    })
      .then(() => {
        setDialogContent(() => ({
          title: "Result",
          body: "Archive of all deleted nostro process has been taken over.",
          confirm: false,
        }));
        dialog.current && dialog.current.showModal();
      })
      .finally(() => {
        setComponentPanelFetching(true);
      });
  }

  function updateAllRelationshipManager() {
    setComponentPanelFetching(true);
    authorizedCall(JobControllerService.restartJob, {
      jobName: "IMPORT_RM",
    })
      .then(() => {
        setDialogContent(() => ({
          title: "Result",
          body: "Update of all relationship manager has been taken over.",
          confirm: false,
        }));
        dialog.current && dialog.current.showModal();
      })
      .finally(() => {
        setComponentPanelFetching(false);
      });
  }

  function confirmDialog() {
    console.log("confirmDialog..");
    dialog.current && dialog.current.close();

    switch (action) {
      case "import-account-balance":
        importAccountBalance();
        break;
      case "export-nos-vs-acc-bal":
        exportNostroVsAccountBalance();
        break;
      case "export-nos-lim":
        exportNostroLimits();
        break;
      case "import-nostro":
        updateAllAcc();
        break;
      case "archive-nostro":
        archiveNos();
        break;
      case "update-rel-manager":
        updateAllRelationshipManager();
        break;
    }
  }

  return (
    <ConfirmModal
      content={dialogContent}
      onClose={closeDialog}
      onConfirm={confirmDialog}
      ref={dialog}
    />
  );
};

export default RenderModal;
