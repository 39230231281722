import { Navigate, Route, Routes } from "react-router-dom";
import { Home } from "../pages/Home";
import { AccountView } from "../pages/AccountView";
import AccountCategories from "../pages/AccountCategories";
import AccountCategoriesEdit from "../pages/AccountCategoriesEdit";
import ViewRelationshipManager from "../pages/ViewRelationshipManager";
import CleanState from "./CleanState";
import { AccountViewEdit } from "../pages/AccountViewEdit";
import { AccountCommentEdit } from "../pages/AccountCommentEdit";
import NostroVsAccountBalance from "../pages/NostroVsAccountBalance";
import AccountBalanceLimitBreach from "../pages/AccountBalanceLimitBreach";
import AccountBalanceCobDate from "../pages/AccountBalanceCobDate";
import AccountBalanceMissingCob from "../pages/AccountBalanceMissingCob";
import OtherNostroViews from "../pages/OtherNostroViews";
import { AccountLimitEdit } from "../pages/AccountLimitEdit";
import EmailPage from "../pages/EmailPage";
import { EmailTemplatePage } from "../pages/EmailTemplatePage";
import { ReportPreview } from "../pages/ReportPreview";
import { RouteGuard } from "../auth/RouteGuard";
import StagingUpdate from "../pages/StagingUpdate";

export const AppRoutes = () => {
  return (
    <CleanState>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route
          path="/acc-view"
          element={
            <RouteGuard>
              <AccountView />
            </RouteGuard>
          }
        />
        <Route
          path="/other-views"
          element={
            <RouteGuard>
              <OtherNostroViews />
            </RouteGuard>
          }
        />
        <Route
          path="/acc-view/:id"
          element={
            <RouteGuard>
              <AccountViewEdit />
            </RouteGuard>
          }
        />
        <Route
          path="/acc-cat-view"
          element={
            <RouteGuard>
              <AccountCategories />
            </RouteGuard>
          }
        />
        <Route
          path="/acc-cat-view/:id"
          element={
            <RouteGuard>
              <AccountCategoriesEdit />
            </RouteGuard>
          }
        />
        <Route
          path="/acc-cat-view/new"
          element={
            <RouteGuard>
              <AccountCategoriesEdit />
            </RouteGuard>
          }
        />
        <Route
          path="/email-without-balance"
          element={
            <RouteGuard>
              <EmailPage type="WITHOUT_BALANCE" />
            </RouteGuard>
          }
        />
        <Route
          path="/email-with-balance"
          element={
            <RouteGuard>
              <EmailPage type="WITH_BALANCE" />
            </RouteGuard>
          }
        />
        <Route
          path="/email-wo-bal-templ"
          element={
            <RouteGuard>
              <EmailTemplatePage type="WITHOUT_BALANCE" />
            </RouteGuard>
          }
        />
        <Route
          path="/email-w-bal-templ"
          element={
            <RouteGuard>
              <EmailTemplatePage type="WITH_BALANCE" />
            </RouteGuard>
          }
        />
        <Route
          path="/other-views/nos-vs-acc-bal"
          element={
            <RouteGuard>
              <NostroVsAccountBalance />
            </RouteGuard>
          }
        />
        <Route
          path="/other-views/acc-bal-lim-br"
          element={
            <RouteGuard>
              <AccountBalanceLimitBreach />
            </RouteGuard>
          }
        />
        <Route
          path="/other-views/cob-dt-vs-acc-bal"
          element={
            <RouteGuard>
              <AccountBalanceCobDate />
            </RouteGuard>
          }
        />
        <Route
          path="/other-views/cob-dt-missing"
          element={
            <RouteGuard>
              <AccountBalanceMissingCob />
            </RouteGuard>
          }
        />
        <Route
          path="/view-relationship-manager"
          element={
            <RouteGuard>
              <ViewRelationshipManager />
            </RouteGuard>
          }
        />
        <Route
          path="/view-relationship-manager/:id"
          element={
            <RouteGuard>
              <ViewRelationshipManager />
            </RouteGuard>
          }
        />
        <Route
          path="/acc-comment-edit/:accId/:commId"
          element={
            <RouteGuard>
              <AccountCommentEdit />
            </RouteGuard>
          }
        />
        <Route
          path="/acc-limit-edit/:nosId/new"
          element={
            <RouteGuard>
              <AccountLimitEdit />
            </RouteGuard>
          }
        />
        <Route
          path="/rep-acc-bal-exp/:id?"
          element={
            <RouteGuard>
              <ReportPreview type="ACC_BAL_EXP" />
            </RouteGuard>
          }
        />
        <Route
          path="/rep-acc-bal-lim-br/:id?"
          element={
            <RouteGuard>
              <ReportPreview type="ACC_BAL_LIM_BR" />
            </RouteGuard>
          }
        />
        <Route
          path="/rep-nos-com/:id?"
          element={
            <RouteGuard>
              <ReportPreview type="ACC_COMMENTS" />
            </RouteGuard>
          }
        />
        <Route path="/stg-upd" element={<StagingUpdate />} />
      </Routes>
    </CleanState>
  );
};
