import { ZfCheckbox, ZfSearchField, ZfTable } from "@ccx/zafire-react";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../common/ZfTable/zftable-configuration";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import authorizedCall from "../../utils/authorizedCallUtils";
import { NostroControllerService } from "../../openapi";
import Spinner from "../common/Spinner";
import { importCellFormatter } from "../common/ZfTable/zftable-cell-editors";
import { StoreSlices } from "../../store/store";
import { setSelectedNostroAccountBalanceSlice } from "../../store/select-item-slice-impl/selected-item-slice-nostro-acc-balance";

export const AccountViewEditAccountBalance: FC<{ nosId: number }> = (props) => {
  const [error, setError] = useState(false);

  const searchFilter = useRef<HTMLZfSearchFieldElement>(null);
  const dataTable = useRef<HTMLZfTableElement>(null);

  const [accBalFetching, setAccBalFetching] = useState(false);
  const [filterBreached, setFilterBreached] = useState({
    kstaValueChfBreach: false,
    kstaValueCcyBreach: false,
    busaldoValueChfBreach: false,
    busaldoValueCcyBreach: false,
  });

  useEffect(() => {
    const defaultOptions = zfTableDefaultOptions(setError);

    const TABLE_COLUMNS = [
      { title: "Account Number", field: "number", maxWidth: 120 },
      { title: "CCY", field: "currency", maxWidth: 80 },
      { title: "COB DT", field: "cobDate", maxWidth: 120 },
      { title: "Description", field: "description", maxWidth: 500 },
      {
        title: "KSTA VAL CHF",
        field: "kstaValueChf",
        headerHozAlign: "right",
        hozAlign: "right",
        formatter: (cell: any) => formatCell(cell, "kstaValueChfBreach"),
      },
      {
        title: "KSTA VAL CCY",
        field: "kstaValueCcy",
        headerHozAlign: "right",
        hozAlign: "right",
        formatter: (cell: any) => formatCell(cell, "kstaValueCcyBreach"),
      },
      { title: "BuSa DT", field: "busaldoDate", maxWidth: 120 },
      {
        title: "BuSaldo VAL CHF",
        field: "busaldoValueChf",
        headerHozAlign: "right",
        hozAlign: "right",
        formatter: (cell: any) => formatCell(cell, "busaldoValueChfBreach"),
      },
      {
        title: "BuSaldo VAL CCY",
        field: "busaldoValueCcy",
        headerHozAlign: "right",
        hozAlign: "right",
        formatter: (cell: any) => formatCell(cell, "busaldoValueCcyBreach"),
      },
    ];

    const actionColumns: [] = [];

    async function dataTableApiCall(url: any, config: any, params: any) {
      console.log("dataTableCall");

      setAccBalFetching(true);
      const sortArray: Array<string> =
        params.sort.length > 0
          ? [`${params.sort[0].field},${params.sort[0].dir}`]
          : [];
      let apiParams: Parameters<
        typeof NostroControllerService.getAllAccountBalance
      >[0] = {
        nostroPathId: props.nosId,
        page: params.page,
        size: params.size,
        sort: sortArray,
      };

      if (searchFilter.current?.value) {
        apiParams = { ...apiParams, search: searchFilter.current?.value };
      }

      if (filterBreached.kstaValueChfBreach) {
        apiParams = {
          ...apiParams,
          kstaValueChfBreach: filterBreached.kstaValueChfBreach,
        };
      }
      if (filterBreached.kstaValueCcyBreach) {
        apiParams = {
          ...apiParams,
          kstaValueCcyBreach: filterBreached.kstaValueCcyBreach,
        };
      }
      if (filterBreached.busaldoValueChfBreach) {
        apiParams = {
          ...apiParams,
          busaldoValueChfBreach: filterBreached.busaldoValueChfBreach,
        };
      }
      if (filterBreached.busaldoValueCcyBreach) {
        apiParams = {
          ...apiParams,
          busaldoValueCcyBreach: filterBreached.busaldoValueCcyBreach,
        };
      }

      return authorizedCall(
        NostroControllerService.getAllAccountBalance,
        apiParams,
        true
      )
        .then((resp) => {
          return resp;
        })
        .catch((error) => {
          /*do nothing*/
        })
        .finally(() => {
          setAccBalFetching(false);
        });
    }

    setError(false);
    dataTable.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableApiCall,
      selectable: 1,
    };
  }, [props.nosId, filterBreached]);

  function refreshTable(event: any) {
    dataTable.current!.options = { ...dataTable.current!.options };
  }

  console.log("RENDER - AccountView [commFetching:", accBalFetching, "]");

  function formatCell(cell: any, controlSource: string) {
    const formattedImport = importCellFormatter(cell);
    if (cell.getData()[controlSource])
      return `<zf-pill type="solid" color="red">${formattedImport}</zf-pill>`;

    return formattedImport;
  }

  const tableBuiltHandlerCallback = useCallback((event: any) => {
    tableBuiltHandler(
      event,
      setAccBalFetching,
      undefined,
      StoreSlices.selectedNostroAccountBalanceSlice,
      setSelectedNostroAccountBalanceSlice
    );
  }, []);

  return (
    <>
      <div
        className="flex flex-row pb-6"
        style={{ borderBottom: "1px solid #ddd" }}
      >
        <div className="basis-1/2"></div>
        <div className="basis-1/4">
          <ZfSearchField
            placeholder="Search ..."
            onInputChange={(e) => {
              refreshTable(e);
            }}
            ref={searchFilter}
          ></ZfSearchField>
        </div>
        <div className="basis-1/4"></div>
        <div className="basis-1/4">
          <ZfCheckbox
            label="KSTA VAL CHF Breached"
            //checked={formData?.largeTurnover.value}
            onCheckboxCheck={(event) => {
              setFilterBreached({
                ...filterBreached,
                kstaValueChfBreach: event.detail,
              });
            }}
            name="KSTA_VAL_CHF"
          ></ZfCheckbox>
          <ZfCheckbox
            label="KSTA VAL CCY Breached"
            //checked={formData?.largeTurnover.value}
            onCheckboxCheck={(event) => {
              setFilterBreached({
                ...filterBreached,
                kstaValueCcyBreach: event.detail,
              });
            }}
            name="KSTA_VAL_CCY"
          ></ZfCheckbox>
          <ZfCheckbox
            label="BuSaldo VAL CHF Breached"
            //checked={formData?.largeTurnover.value}
            onCheckboxCheck={(event) => {
              setFilterBreached({
                ...filterBreached,
                busaldoValueChfBreach: event.detail,
              });
            }}
            name="BuSaldo_VAL_CHF"
          ></ZfCheckbox>
          <ZfCheckbox
            label="BuSaldo VAL CCY Breached"
            //checked={formData?.largeTurnover.value}
            onCheckboxCheck={(event) => {
              setFilterBreached({
                ...filterBreached,
                busaldoValueCcyBreach: event.detail,
              });
            }}
            name="BuSaldo_VAL_CCY"
          ></ZfCheckbox>
        </div>
      </div>

      {error && <p className="error">Error in loading data</p>}

      <Spinner fetching={accBalFetching} />

      <ZfTable
        ref={dataTable}
        onTableBuilt={tableBuiltHandlerCallback}
        className="mt-5"
        data-testid="acc-bal-data-table"
        style={{
          pointerEvents: accBalFetching && "none",
          opacity: accBalFetching && 0.7,
        }}
      ></ZfTable>
    </>
  );
};
