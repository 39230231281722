import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface PreviousPageState {
  path: string | null;
  params?: Record<string, string>;
}

const initialState: PreviousPageState = {
  path: null,
  params: {},
};

const previousPageSlice = createSlice({
  name: "previousPage",
  initialState,
  reducers: {
    setPreviousPage: (state, action: PayloadAction<PreviousPageState>) => {
      state.path = action.payload.path;
      state.params = action.payload.params;
    },
    clearPreviousPage: (state) => {
      state.path = null;
      state.params = {};
    },
  },
});

// Export actions from the slice
export const previousPageActions = previousPageSlice.actions;

// Export selectors
export const selectPreviousPage = (state: RootState) => state.previousPageSlice;

// Export reducer
export default previousPageSlice.reducer;
