import { createSlice } from "@reduxjs/toolkit";
import {
  checkForTokens,
  logout,
  readAuthToken,
  readUpn,
  toLoginPage,
} from "./auth-utils";

export type Authentication = {
  accessToken?: string;
  idToken?: string;
  refreshToken?: string;
  upn?: string;
};

export interface AuthState {
  isAuth: boolean;
  isLoading: boolean;
  authTokens?: Authentication;
  authError: number;
}

const initAuthState: AuthState = {
  isAuth: checkForTokens(),
  isLoading: false,
  authTokens: readAuthToken(),
  authError: 0,
};

const AuthSlice = createSlice({
  name: "auth-slice",
  initialState: initAuthState,
  reducers: {
    signIn(state, actions) {
      let authTokens: Authentication = actions.payload;
      if (authTokens.idToken === undefined || authTokens.idToken === "") return;
      const upn = readUpn(authTokens.idToken);

      if (!authTokens.refreshToken) {
        authTokens = {
          ...authTokens,
          refreshToken: state.authTokens?.refreshToken,
        };
      }

      authTokens.upn = upn;

      localStorage.setItem("authTokens", JSON.stringify(authTokens));
      state.isAuth = true;
      state.authTokens = authTokens;
    },
    signOut(state) {
      state.isAuth = false;
      logout(state.authTokens?.idToken);
    },
    loading(state, actions) {
      state.isLoading = actions.payload;
    },
    authError(state, actions) {
      state.authError = actions.payload;
      toLoginPage();
    },
  },
});

export const authActions = AuthSlice.actions;

export default AuthSlice.reducer;
