import { useCallback, useEffect, useRef, useState } from "react";
import {
  ZfIconButton,
  ZfLoadingSpinner,
  ZfSearchField,
  ZfTable,
  ZfTextButton,
} from "@ccx/zafire-react";
import { AuthenticatedLayout } from "../common/components/AuthenticatedLayout";
import { useNavigate } from "react-router-dom";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../common/ZfTable/zftable-configuration";
import authorizedCall from "../../utils/authorizedCallUtils";
import { NostroControllerService } from "../../openapi";
import { StoreSlices, useAppSelector } from "../../store/store";
import { setSelectedNostroVsAccount } from "../../store/select-item-slice-impl/selected-item-slice-nostro-vs-account";

const NostroVsAccountBalance = () => {
  const navigate = useNavigate();
  //const dispatch = useAppDispatch();

  const dataTable = useRef<HTMLZfTableElement>(null);
  const searchFilter = useRef<HTMLZfSearchFieldElement>(null);

  const selected = useAppSelector(
    (state) => state.selectedNostroVsAccount.selected
  );

  const [error, setError] = useState(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  /**
   *
   * @param url the url of the request
   * @param config the ajaxConfig object
   * @param params the ajaxParams object
   * @returns
   */
  async function dataTableApiCall(url: any, config: any, params: any) {
    setIsFetching(true);
    const sortArray: Array<string> =
      params.sort.length > 0
        ? [`${params.sort[0].field},${params.sort[0].dir}`]
        : [];
    let apiParams: Parameters<
      typeof NostroControllerService.getAllNostroVsAccountBalance
    >[0] = {
      page: params.page,
      size: params.size,
      sort: sortArray,
    };

    if (searchFilter.current?.value) {
      apiParams = { ...apiParams, search: searchFilter.current?.value };
    }

    return authorizedCall(
      NostroControllerService.getAllNostroVsAccountBalance,
      apiParams,
      true
    )
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error;
      })
      .finally(() => {
        setIsFetching(false);
      });
  }

  /**
   *
   * First configuration after renderization
   */
  useEffect(() => {
    const defaultOptions = zfTableDefaultOptions(setError);

    const TABLE_COLUMNS = [
      {
        formatter: "zfRadioRowSelection",
        hozAlign: "center",
        headerSort: false,
        width: 50,
      },
      { title: "Nostro Number", field: "number", maxWidth: 140 },
      { title: "Nostro Name", field: "name" },
      { title: "Risk Fraud", field: "riskFraud" },
      { title: "Status", field: "status" },
      { title: "Account Balance Count", field: "count" },
      { title: "Min COB Date", field: "minCobDate" },
      { title: "Max COB Date", field: "maxCobDate" },
    ];

    const actionColumns: [] = [];

    setError(false);
    dataTable.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableApiCall,
      selectable: 1,
    };
  }, []);

  function filteredSearch(event: any) {
    dataTable.current!.options = { ...dataTable.current!.options };
  }

  const tableBuiltHandlerCallback = useCallback(
    (event: any) =>
      tableBuiltHandler(
        event,
        setIsFetching,
        undefined,
        StoreSlices.selectedNostroVsAccount,
        setSelectedNostroVsAccount
      ),
    []
  );

  /*function rowSelectionChanged(row: any) {
    //row - row component
    const selected: EntityModelNostroVsAccountBalanceDto = row.detail.data[0];
    setSelected(selected);
    console.log("rowSelectionChanged: ", selected);
  }*/

  /*const handleEditClick = () => {
    navigateWithHistory(
      navigate,
      dispatch,
      `/acc-view/${selected?.id}`,
      "/other-views/nos-vs-acc-bal"
      //{ id: "123" }
    );
  };*/

  console.log("RENDER - AccountCategories [isFetching:", isFetching, "]");

  return (
    <AuthenticatedLayout
      breadcrumbList={[
        { name: "ACTION PANEL", route: "/" },
        { name: "Other Views", route: "/other-views" },
        {
          name: "Nostro Vs Account Balance",
          route: "/other-views/nos-vs-acc-bal",
        },
      ]}
    >
      <div className="content p-4 bg-white">
        <div
          className="flex flex-row pb-6"
          style={{ borderBottom: "1px solid #ddd" }}
        >
          <div className="basis-1/2">
            <p
              className="heading--two text-xl"
              data-testid="view-acc-cat-pagetitle"
            >
              Nostro vs Account Balance
            </p>
          </div>
          <div className="basis-1/4">
            <ZfSearchField
              placeholder="Search ..."
              onInputChange={(e) => {
                filteredSearch(e);
              }}
              ref={searchFilter}
            ></ZfSearchField>
          </div>
          <div className="basis-1/4"></div>
          <div className="basis-1/4 flex flex-row-reverse">
            <ZfTextButton
              disabled={selected === undefined}
              onClick={() => navigate(`/acc-view/${selected?.id}`)}
              hierarchy="primary"
              className="mr-1"
              size="small"
            >
              Edit
            </ZfTextButton>
            <ZfIconButton
              hierarchy="secondary"
              icon="arrow_back"
              className="mr-1"
              onClick={() => navigate(`/other-views`)}
              size="small"
            ></ZfIconButton>
          </div>
        </div>
        {error && <p className="error">Error in loading data</p>}

        {isFetching && (
          <ZfLoadingSpinner
            size="large"
            color="primary"
            type="infinite"
            style={{
              position: "absolute",
              left: "50%",
              right: "50%",
              zIndex: "100",
              top: "35%",
            }}
            data-testid="spinner"
          ></ZfLoadingSpinner>
        )}

        <ZfTable
          ref={dataTable}
          onTableBuilt={(event) => tableBuiltHandlerCallback(event)}
          //onTableRowSelectionChanged={rowSelectionChanged}
          className="mt-5"
          style={{
            pointerEvents: isFetching && "none",
            opacity: isFetching && 0.7,
          }}
          data-testid="nos-vs-ab-data-table"
        ></ZfTable>
      </div>
    </AuthenticatedLayout>
  );
};

export default NostroVsAccountBalance;
