import { EntityModelAccountBalanceLimitBreachDto } from "../../openapi";
import { createSelectedItemSlice } from "../selected-item-slice";

// Create specific slices for different types of selected items
const SelectedBalanceLimitBreach =
  createSelectedItemSlice<EntityModelAccountBalanceLimitBreachDto>(
    "selectedBalanceLimitBreach"
  );

export const { setSelectedItem: setSelectedBalanceLimitBreach } =
  SelectedBalanceLimitBreach.actions;

export default SelectedBalanceLimitBreach.reducer;
