import { EntityModelAccountLimitDto } from "../../openapi";
import { createSelectedItemSlice } from "../selected-item-slice";

const SelectedNostroAccountLimitSlice =
  createSelectedItemSlice<EntityModelAccountLimitDto>(
    "selectedNostroAccountLimit"
  );

export const { setSelectedItem: setSelectedNostroAccountLimit } =
  SelectedNostroAccountLimitSlice.actions;

export default SelectedNostroAccountLimitSlice.reducer;
