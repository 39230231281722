import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EntityModelStatusDto } from "../openapi";
import { getComboBoxStatuses } from "./combobox-utils";

interface ComboBoxState {
  statuses: Array<EntityModelStatusDto>;
  loading: boolean;
  error: string | null;
}

const initialState: ComboBoxState = {
  statuses: [],
  loading: false,
  error: null,
};

export const fetchStatuses = createAsyncThunk(
  "comboBoxState/fetchStatuses",
  async () => {
    return await getComboBoxStatuses();
  }
);

const ComboBoxStateSlice = createSlice({
  name: "comboBoxState",
  initialState,
  reducers: {
    setStatuses: (
      state,
      action: PayloadAction<Array<EntityModelStatusDto>>
    ) => {
      state.statuses = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatuses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStatuses.fulfilled, (state, action) => {
        state.statuses = action.payload;
        state.loading = false;
      })
      .addCase(fetchStatuses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch statuses";
      });
  },
});

export const comboBoxActions = ComboBoxStateSlice.actions;
export default ComboBoxStateSlice.reducer;
