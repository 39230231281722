import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import authorizedCall from "../../utils/authorizedCallUtils";
import { NostroControllerService } from "../../openapi";
import { ZfLoadingSpinner } from "@ccx/zafire-react";

export interface ProtectedRouteProps {
  children: ReactNode;
}

export const RouteGuard = ({ children }: ProtectedRouteProps) => {
  const [showChildren, setShowChildren] = useState<boolean | undefined>(
    undefined
  );
  const [isFetching, setIsFetching] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function isImportToConfirm() {
      setIsFetching(true);
      try {
        const resp = await authorizedCall(
          NostroControllerService.getAllNostroToUpdate,
          { page: 1, size: 1 },
          true
        );
        console.log("getAllNostroToUpdate", resp);
        setIsFetching(false);
        resp.page.totalElements !== 0
          ? navigate("/stg-upd")
          : setShowChildren(true);
      } catch (_) {
        //error
      }
    }
    isImportToConfirm();
  }, [navigate]);

  return (
    <>
      {isFetching && (
        <ZfLoadingSpinner
          size="large"
          color="primary"
          type="infinite"
          style={{
            position: "absolute",
            left: "50%",
            right: "50%",
            zIndex: "100",
            top: "35%",
          }}
          data-testid="spinner"
        ></ZfLoadingSpinner>
      )}
      {showChildren && children}
    </>
  );
  // if (importToConfirm) {
  //   return <Navigate to="/nos-to-upd" />;
  // } else {
  //   return <></>;
  // }
};
