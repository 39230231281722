/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CollectionModelEntityModelJobParametersDto } from "../models/CollectionModelEntityModelJobParametersDto";
import type { JobParametersDto } from "../models/JobParametersDto";
import type { PagedModelEntityModelJobLogDto } from "../models/PagedModelEntityModelJobLogDto";
import type { PagedModelEntityModelJobRunInfoDto } from "../models/PagedModelEntityModelJobRunInfoDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class JobControllerService {
  /**
   * Restarts a job
   * @returns void
   * @throws ApiError
   */
  public static restartJob({
    jobName,
  }: {
    jobName: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/job-scheduling/{jobName}",
      path: {
        jobName: jobName,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Returns the job configurations, such as hour, day, etc.
   * @returns CollectionModelEntityModelJobParametersDto Scheduled job configurations
   * @throws ApiError
   */
  public static getJobScheduling(): CancelablePromise<CollectionModelEntityModelJobParametersDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/job-scheduling",
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Edits a job configuration
   * @returns void
   * @throws ApiError
   */
  public static editJobConfig({
    requestBody,
  }: {
    requestBody: JobParametersDto;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/job-scheduling",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Returns the last job run log.
   * @returns PagedModelEntityModelJobLogDto Last job run log
   * @throws ApiError
   */
  public static getLastJobRun({
    jobName,
  }: {
    jobName: string;
  }): CancelablePromise<PagedModelEntityModelJobLogDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/job-scheduling/{jobName}/last-run",
      path: {
        jobName: jobName,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Returns all job run information.
   * @returns PagedModelEntityModelJobRunInfoDto all job run information
   * @throws ApiError
   */
  public static getAllJobsRunInfo(): CancelablePromise<PagedModelEntityModelJobRunInfoDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/job-scheduling/run-info",
      errors: {
        403: `Unauthorized user`,
      },
    });
  }
}
