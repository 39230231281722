import { FC, PropsWithChildren, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { appStateActions } from "../../store/appstate-slice";
import { useAppDispatch } from "../../store/store";
import { previousPageActions } from "../../store/previous-page-slice";

const CleanState: FC<PropsWithChildren> = (props) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  //const previousPage = useAppSelector((state) => state.previousPageSlice.path);

  useEffect(() => {
    console.log("route to", location.pathname);
    dispatch(appStateActions.error(undefined));

    if (
      location.pathname.startsWith("/other-views/") ||
      location.pathname === "/acc-view"
    ) {
      console.log("set navigation history", location.pathname);
      dispatch(
        previousPageActions.setPreviousPage({
          path: location.pathname,
          params: undefined,
        })
      );
    }
  }, [location.pathname, dispatch]);

  return <>{props.children}</>;
};

export default CleanState;

