import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ZfCard,
  ZfCardContent,
  ZfIconButton,
  ZfLoadingSpinner,
  ZfSelectField,
  ZfSelectOption,
  ZfTextButton,
} from "@ccx/zafire-react";
import { useReportPreview } from "./useReportPreview";
import { AuthenticatedLayout } from "../common/components/AuthenticatedLayout";
import { Embed } from "../common/components/Embed";
import authorizedCall from "../../utils/authorizedCallUtils";
import { ReportControllerService, ReportRequest } from "../../openapi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppError, appStateActions } from "../../store/appstate-slice";
import { store } from "../../store/store";

interface ReportPreviewProps {
  type: "ACC_BAL_LIM_BR" | "ACC_BAL_EXP" | "ACC_COMMENTS";
}

export const ReportPreview = ({ type }: ReportPreviewProps) => {
  const [isCreating, setIsCreating] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const createReport = useCallback(
    (request: ReportRequest) => {
      setIsCreating(true);

      let apiParams: Parameters<
        typeof ReportControllerService.createReport
      >[0] = {
        requestBody: request,
      };

      authorizedCall(ReportControllerService.createReport, apiParams, true)
        .then((resp) => {
          if (!resp?.reportId) {
            const err: AppError = {
              code: 204,
              message: "No data found for this report",
            };
            store.dispatch(appStateActions.error(err));
          } else {
            if (id)
              navigate(
                location.pathname.replace(`/${id}`, `/${resp.reportId}`)
              );
            else navigate(location.pathname + "/" + resp.reportId);
          }
          return resp;
        })
        .catch((error) => {
          return error;
        })
        .finally(() => {
          setIsCreating(false);
        });
    },
    [id, location.pathname, navigate]
  );

  useEffect(() => {
    if (!id && type === "ACC_BAL_LIM_BR")
      createReport({
        type: type,
        timestamp: new Date().toISOString(),
        balance: null,
        commentStatus: null,
      });
  }, [createReport, id, type]);

  const {
    reportUrl,
    handleDownloadReport,
    handleBack,
    isFetching,
    reportError,
    // downloadIsAllowed,
  } = useReportPreview();

  const takeRoute = (): string => {
    switch (type) {
      case "ACC_BAL_EXP":
        return "/rep-acc-bal-exp";
      case "ACC_BAL_LIM_BR":
        return "/rep-acc-bal-lim-br";
      case "ACC_COMMENTS":
        return "/rep-nos-com";
    }
  };

  const takeName = (): string => {
    switch (type) {
      case "ACC_BAL_EXP":
        return "Account Balance Exposure";
      case "ACC_BAL_LIM_BR":
        return "Account Balance Limit Breach";
      case "ACC_COMMENTS":
        return "Nostro Comments";
    }
  };

  const renderPdf = useMemo(
    () => <>{reportUrl && <Embed src={reportUrl} />}</>,
    [reportUrl]
  );

  return (
    <AuthenticatedLayout
      breadcrumbList={[
        { name: "ACTION PANEL", route: "/" },
        {
          name: "Report " + takeName(),
          route: "" + takeRoute(),
        },
      ]}
    >
      <div className="content p-4 bg-white">
        {reportError && <p className="error">{reportError}</p>}
        {(isFetching || isCreating) && (
          <ZfLoadingSpinner
            size="large"
            color="primary"
            type="infinite"
            style={{
              position: "absolute",
              left: "50%",
              right: "50%",
              zIndex: "100",
              top: "35%",
            }}
            data-testid="spinner"
          ></ZfLoadingSpinner>
        )}
        <ZfCard
          title="Report Overview"
          //className="flex-1 justify-center"
          //style={{ width: "1512px" }}
        >
          <div className="content p-4 bg-white">
            <div className="flex justify-between">
              <div>
                <p
                  className="heading--three text-xl"
                  data-testid="acc-cat-pagetitle"
                >
                  {"Report " + takeName()}
                </p>
              </div>
              {type === "ACC_BAL_EXP" && (
                <ZfSelectField
                  mandatory
                  name="balance"
                  label="Balance"
                  //showPlaceholder={false}
                  // errorText={formData.statusId.textError}
                  // state={formData.statusId.state}
                  // onFocus={focusOnInputHandler}
                  onSelectFieldChange={(event: CustomEvent) => {
                    if (event.detail !== "")
                      createReport({
                        type: type,
                        timestamp: new Date().toISOString(),
                        balance: event.detail,
                        commentStatus: null,
                      });
                  }}
                  //defaultValue="ALL"
                  //helperText="These cars are for sale"
                >
                  <ZfSelectOption value="ALL">all</ZfSelectOption>
                  <ZfSelectOption value="NON_ZERO">{"<>0"}</ZfSelectOption>
                  <ZfSelectOption value="ZERO">=0</ZfSelectOption>
                </ZfSelectField>
              )}
              {type === "ACC_COMMENTS" && (
                <ZfSelectField
                  mandatory
                  name="commentStatus"
                  label="Comment Status"
                  //showPlaceholder={false}
                  // errorText={formData.statusId.textError}
                  // state={formData.statusId.state}
                  // onFocus={focusOnInputHandler}
                  onSelectFieldChange={(event: CustomEvent) => {
                    if (event.detail !== "")
                      createReport({
                        type: type,
                        timestamp: new Date().toISOString(),
                        balance: null,
                        commentStatus: event.detail,
                      });
                  }}
                  data-testid="comment-status-combobox"
                  //defaultValue={"ALL"}
                  //helperText="These cars are for sale"
                >
                  <ZfSelectOption value="ALL">all</ZfSelectOption>
                  <ZfSelectOption value="PENDING">pending</ZfSelectOption>
                </ZfSelectField>
              )}
              <div className="flex">
                <ZfIconButton
                  hierarchy="secondary"
                  icon="arrow_back"
                  className="mr-2"
                  onClick={handleBack}
                  size="small"
                  data-testid="back-button"
                ></ZfIconButton>
                <ZfTextButton
                  hierarchy="primary"
                  disabled={!!reportError || isFetching}
                  onClick={handleDownloadReport}
                  className="mr-2"
                  size="small"
                  data-testid="download-pdf-button"
                >
                  Download PDF
                </ZfTextButton>
              </div>
            </div>
            {/* </div> */}
          </div>
          <ZfCardContent>{renderPdf}</ZfCardContent>
        </ZfCard>
      </div>
    </AuthenticatedLayout>
  );
};
