import {
  EnhancedStore,
  combineReducers,
  configureStore,
  ThunkDispatch,
  Action,
} from "@reduxjs/toolkit";
import AuthReducer from "./auth-slice";
import { useDispatch, useSelector } from "react-redux";
import AppstateSlice from "./appstate-slice";
import TabStateReducer from "./tab-slice";
import ComboBoxReducer from "./combobox-slice";
import SelectedNostroItemReducer from "./select-item-slice-impl/selected-item-slice-nostro";
import SelectedNostroCommentReducer from "./select-item-slice-impl/selected-item-slice-nostro-comment";
import SelectedNostroAccountLimitReducer from "./select-item-slice-impl/selected-item-slice-nostro-acc-limit";
import SelectedAccountCategoryReducer from "./select-item-slice-impl/selected-item-slice-account-category";
import SelectedViewRelManagerReducer from "./select-item-slice-impl/selected-item-slice-view-rel-manager";
import PreviousPageReducer from "./previous-page-slice";
import SelectedNostroVsAccountReducer from "./select-item-slice-impl/selected-item-slice-nostro-vs-account";
import SelectedBalanceLimitBreachReducer from "./select-item-slice-impl/selected-item-slice-balance-lim-breach";
import SelectedNostroNostroLimitLogReducer from "./select-item-slice-impl/selected-item-slice-nostro-nos-limit-log";
import SelectedNostroAccountBalanceReducer from "./select-item-slice-impl/selected-item-slice-nostro-acc-balance";
import SelectedNostroAccountBalHistoryReducer from "./select-item-slice-impl/selected-item-slice-nostro-acc-bal-history";

export enum StoreSlices {
  selectedNostroItemSlice = "selectedNostroItemSlice",
  selectedNostroCommentSlice = "selectedNostroCommentSlice",
  selectedNostroAccountLimitSlice = "selectedNostroAccountLimitSlice",
  selectedAccountCategorySlice = "selectedAccountCategorySlice",
  selectedViewRelManagerSlice = "selectedViewRelManagerSlice",
  selectedNostroVsAccount = "selectedNostroVsAccount",
  selectedBalanceLimitBreach = "selectedBalanceLimitBreach",
  selectedNostroNostroLimitLogSlice = "selectedNostroNostroLimitLogSlice",
  selectedNostroAccountBalanceSlice = "selectedNostroAccountBalanceSlice",
  selectedNostroAccountBalHistorySlice = "selectedNostroAccountBalHistorySlice",
}

const rootReducer = combineReducers({
  authSlice: AuthReducer,
  appStateSlice: AppstateSlice,
  tabStateSlice: TabStateReducer,
  comboBoxSlice: ComboBoxReducer,
  previousPageSlice: PreviousPageReducer,
  [StoreSlices.selectedNostroItemSlice]: SelectedNostroItemReducer,
  [StoreSlices.selectedNostroCommentSlice]: SelectedNostroCommentReducer,
  [StoreSlices.selectedNostroAccountLimitSlice]:
    SelectedNostroAccountLimitReducer,
  [StoreSlices.selectedAccountCategorySlice]: SelectedAccountCategoryReducer,
  [StoreSlices.selectedViewRelManagerSlice]: SelectedViewRelManagerReducer,
  [StoreSlices.selectedNostroVsAccount]: SelectedNostroVsAccountReducer,
  [StoreSlices.selectedBalanceLimitBreach]: SelectedBalanceLimitBreachReducer,
  [StoreSlices.selectedNostroNostroLimitLogSlice]:
    SelectedNostroNostroLimitLogReducer,
  [StoreSlices.selectedNostroAccountBalanceSlice]:
    SelectedNostroAccountBalanceReducer,
  [StoreSlices.selectedNostroAccountBalHistorySlice]:
    SelectedNostroAccountBalHistoryReducer,
});

export let store: EnhancedStore;

export const setupStore = (preloadedState?: Partial<RootState>) => {
  if (
    store !== undefined &&
    (preloadedState === undefined || Object.keys(preloadedState).length === 0)
  )
    return store;
  store = configureStore({
    reducer: rootReducer,
    preloadedState,
  });

  return store;
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = ThunkDispatch<RootState, undefined, Action<string>>;

export const useAppDispatch: () => AppDispatch = useDispatch;
// selectors
export const useAppSelector = useSelector.withTypes<RootState>();
