import { EntityModelNostroCategoryDto } from "../../openapi";
import { createSelectedItemSlice } from "../selected-item-slice";

// Create specific slices for different types of selected items
const SelectedAccountCategorySlice =
  createSelectedItemSlice<EntityModelNostroCategoryDto>(
    "selectedAccountCategory"
  );

export const { setSelectedItem: setSelectedAccountCategory } =
  SelectedAccountCategorySlice.actions;

export default SelectedAccountCategorySlice.reducer;
