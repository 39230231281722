import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Draft } from "immer";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
} from "../components/common/ZfTable/zftable-configuration";

// Define a generic type for the selected item state
export interface SelectedItemState<T> {
  selected: T | null;
  page: number;
  pageSize: number;
  sort: { dir: string; column: string } | null;
}

// Define a payload type for the setSelectedItem action
export interface SetSelectedItemPayload<T> {
  selected: { data: T; cells: Array<any> } | null;
  page: number;
  pageSize: number;
  sort: { dir: string; column: string } | null;
}

// Create a generic slice
export const createSelectedItemSlice = <T>(sliceName: string) => {
  return createSlice({
    name: sliceName,
    initialState: {
      selected: null,
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      sort: null,
    } as SelectedItemState<T>,
    reducers: {
      setSelectedItem: (
        state,
        action: PayloadAction<SetSelectedItemPayload<T>>
      ) => {
        state.selected = action.payload.selected as Draft<T> | null;
        state.page = action.payload.page;
        state.pageSize = action.payload.pageSize;
        state.sort = action.payload.sort;
      },
    },
  });
};
