/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountLimitCreateDto } from "../models/AccountLimitCreateDto";
import type { AccountLimitDto } from "../models/AccountLimitDto";
import type { AccountLimitUpdateDto } from "../models/AccountLimitUpdateDto";
import type { NostroCommentCreateDto } from "../models/NostroCommentCreateDto";
import type { NostroCommentDto } from "../models/NostroCommentDto";
import type { NostroCommentUpdateDto } from "../models/NostroCommentUpdateDto";
import type { NostroDetailDto } from "../models/NostroDetailDto";
import type { NostroDetailUpdateDto } from "../models/NostroDetailUpdateDto";
import type { NostroUpdateSelectionDto } from "../models/NostroUpdateSelectionDto";
import type { PagedModelEntityModelAccountBalanceCobDto } from "../models/PagedModelEntityModelAccountBalanceCobDto";
import type { PagedModelEntityModelAccountBalanceDto } from "../models/PagedModelEntityModelAccountBalanceDto";
import type { PagedModelEntityModelAccountBalanceLimitBreachDto } from "../models/PagedModelEntityModelAccountBalanceLimitBreachDto";
import type { PagedModelEntityModelAccountBalanceMissingCobDto } from "../models/PagedModelEntityModelAccountBalanceMissingCobDto";
import type { PagedModelEntityModelAccountDto } from "../models/PagedModelEntityModelAccountDto";
import type { PagedModelEntityModelAccountLimitDto } from "../models/PagedModelEntityModelAccountLimitDto";
import type { PagedModelEntityModelNostroCommentDto } from "../models/PagedModelEntityModelNostroCommentDto";
import type { PagedModelEntityModelNostroLimitDto } from "../models/PagedModelEntityModelNostroLimitDto";
import type { PagedModelEntityModelNostroOverviewDto } from "../models/PagedModelEntityModelNostroOverviewDto";
import type { PagedModelEntityModelNostroStagingDeleteDto } from "../models/PagedModelEntityModelNostroStagingDeleteDto";
import type { PagedModelEntityModelNostroStagingUpdateDto } from "../models/PagedModelEntityModelNostroStagingUpdateDto";
import type { PagedModelEntityModelNostroVsAccountBalanceDto } from "../models/PagedModelEntityModelNostroVsAccountBalanceDto";
import type { PagedModelEntityModelOtherNostroViewDto } from "../models/PagedModelEntityModelOtherNostroViewDto";
import type { ReportResponse } from "../models/ReportResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class NostroControllerService {
  /**
   * Returns a paged list of NostroCommentDto entities
   * @returns PagedModelEntityModelNostroCommentDto Paged list of NostroCommentDto entities
   * @throws ApiError
   */
  public static getAllCommentsForNostro({
    nostroPathId,
    page,
    size = 10,
    sort,
    search,
    commentLineCount,
    nostro,
    link,
    nostroCommentStatus,
    showOnReport,
    comment,
    id,
  }: {
    nostroPathId: number;
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    search?: string;
    commentLineCount?: number;
    nostro?: string;
    link?: string;
    nostroCommentStatus?: string;
    showOnReport?: boolean;
    comment?: string;
    id?: number;
  }): CancelablePromise<PagedModelEntityModelNostroCommentDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos/{nostroPathId}/comment",
      path: {
        nostroPathId: nostroPathId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
        search: search,
        commentLineCount: commentLineCount,
        nostro: nostro,
        link: link,
        nostroCommentStatus: nostroCommentStatus,
        showOnReport: showOnReport,
        comment: comment,
        id: id,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Add new NostroComment entity
   * @returns NostroCommentDto NostroComment successfully created
   * @throws ApiError
   */
  public static createNostroComment({
    nostroPathId,
    requestBody,
  }: {
    nostroPathId: number;
    requestBody: NostroCommentCreateDto;
  }): CancelablePromise<NostroCommentDto> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/nos/{nostroPathId}/comment",
      path: {
        nostroPathId: nostroPathId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Returns a paged list of AccountLimitDto entities
   * @returns PagedModelEntityModelAccountLimitDto Paged list of AccountLimitDto entities
   * @throws ApiError
   */
  public static getAllAccountLimitForNostro({
    nostroPathId,
    page,
    size = 10,
    sort,
    search,
    busaldoValueCcyLimitLow,
    kstaValueCcyLimitLow,
    id,
    validFrom,
    kstaValueCcyLimitHigh,
    account,
    busaldoValueCcyLimitHigh,
    status,
    validTo,
  }: {
    nostroPathId: number;
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    search?: string;
    busaldoValueCcyLimitLow?: number;
    kstaValueCcyLimitLow?: number;
    id?: number;
    validFrom?: string;
    kstaValueCcyLimitHigh?: number;
    account?: string;
    busaldoValueCcyLimitHigh?: number;
    status?: string;
    validTo?: string;
  }): CancelablePromise<PagedModelEntityModelAccountLimitDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos/{nostroPathId}/account-limit",
      path: {
        nostroPathId: nostroPathId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
        search: search,
        busaldoValueCcyLimitLow: busaldoValueCcyLimitLow,
        kstaValueCcyLimitLow: kstaValueCcyLimitLow,
        id: id,
        validFrom: validFrom,
        kstaValueCcyLimitHigh: kstaValueCcyLimitHigh,
        account: account,
        busaldoValueCcyLimitHigh: busaldoValueCcyLimitHigh,
        status: status,
        validTo: validTo,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Add new AccountLimit entity
   * @returns AccountLimitDto AccountLimit successfully created
   * @throws ApiError
   */
  public static createAccountLimit({
    nostroPathId,
    requestBody,
  }: {
    nostroPathId: number;
    requestBody: AccountLimitCreateDto;
  }): CancelablePromise<AccountLimitDto> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/nos/{nostroPathId}/account-limit",
      path: {
        nostroPathId: nostroPathId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Updates all selected Nostro with data coming from staging table
   * @returns ReportResponse Selected Nostros updated
   * @throws ApiError
   */
  public static updateSelectedNostro({
    requestBody,
  }: {
    requestBody: NostroUpdateSelectionDto;
  }): CancelablePromise<ReportResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/nos/update",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        403: `Unauthorized user`,
        404: `Record not found`,
      },
    });
  }

  /**
   * Returns a NostroDetailDto entity
   * @returns NostroDetailDto NostroDetailDto entity
   * @throws ApiError
   */
  public static getNostroDetail({
    nostroPathId,
  }: {
    nostroPathId: number;
  }): CancelablePromise<NostroDetailDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos/{nostroPathId}",
      path: {
        nostroPathId: nostroPathId,
      },
      errors: {
        403: `Unauthorized user`,
        404: `Record not found`,
      },
    });
  }

  /**
   * Updates a specific Nostro and its limits to find using the id sent
   * @returns NostroDetailDto Record updated
   * @throws ApiError
   */
  public static updateNostroDetail({
    nostroPathId,
    requestBody,
  }: {
    nostroPathId: number;
    requestBody: NostroDetailUpdateDto;
  }): CancelablePromise<NostroDetailDto> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/nos/{nostroPathId}",
      path: {
        nostroPathId: nostroPathId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        403: `Unauthorized user`,
        404: `Record not found`,
      },
    });
  }

  /**
   * Returns a NostroCommentDto entity
   * @returns NostroCommentDto NostroCommentDto entity
   * @throws ApiError
   */
  public static getNostroCommentDetail({
    nostroPathId,
    nostroCommentPathId,
  }: {
    nostroPathId: number;
    nostroCommentPathId: number;
  }): CancelablePromise<NostroCommentDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos/{nostroPathId}/comment/{nostroCommentPathId}",
      path: {
        nostroPathId: nostroPathId,
        nostroCommentPathId: nostroCommentPathId,
      },
      errors: {
        403: `Unauthorized user`,
        404: `Record not found`,
      },
    });
  }

  /**
   * Updates a specific NostroComment to find using the nostroCommentId sent
   * @returns NostroCommentDto NostroComment updated
   * @throws ApiError
   */
  public static updateNostroComment({
    nostroPathId,
    nostroCommentPathId,
    requestBody,
  }: {
    nostroPathId: number;
    nostroCommentPathId: number;
    requestBody: NostroCommentUpdateDto;
  }): CancelablePromise<NostroCommentDto> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/nos/{nostroPathId}/comment/{nostroCommentPathId}",
      path: {
        nostroPathId: nostroPathId,
        nostroCommentPathId: nostroCommentPathId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        403: `Unauthorized user`,
        404: `Record not found`,
      },
    });
  }

  /**
   * Updates a specific AccountLimit to find using the limitId sent
   * @returns AccountLimitDto AccountLimit updated
   * @throws ApiError
   */
  public static updateAccountLimit({
    nostroPathId,
    limitId,
    requestBody,
  }: {
    nostroPathId: number;
    limitId: number;
    requestBody: AccountLimitUpdateDto;
  }): CancelablePromise<AccountLimitDto> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/nos/{nostroPathId}/account-limit/{limitId}",
      path: {
        nostroPathId: nostroPathId,
        limitId: limitId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        403: `Unauthorized user`,
        404: `Record not found`,
      },
    });
  }

  /**
   * Returns a paged list of NostroOverviewDto entities
   * @returns PagedModelEntityModelNostroOverviewDto Paged list of NostroOverviewDto entities
   * @throws ApiError
   */
  public static getAllNostro({
    page,
    size = 10,
    sort,
    search,
    riskComplianceCode,
    code,
    limitCount,
    riskFraudCode,
    kstaChfBr,
    kstaCcyBr,
    commentCount,
    busaldoChfBr,
    number,
    upn,
    name,
    busaldoCcyBr,
    id,
    status,
  }: {
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    search?: string;
    riskComplianceCode?: string;
    code?: string;
    limitCount?: number;
    riskFraudCode?: string;
    kstaChfBr?: number;
    kstaCcyBr?: number;
    commentCount?: number;
    busaldoChfBr?: number;
    number?: string;
    upn?: string;
    name?: string;
    busaldoCcyBr?: number;
    id?: number;
    status?: string;
  }): CancelablePromise<PagedModelEntityModelNostroOverviewDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos",
      query: {
        page: page,
        size: size,
        sort: sort,
        search: search,
        riskComplianceCode: riskComplianceCode,
        code: code,
        limitCount: limitCount,
        riskFraudCode: riskFraudCode,
        kstaChfBr: kstaChfBr,
        kstaCcyBr: kstaCcyBr,
        commentCount: commentCount,
        busaldoChfBr: busaldoChfBr,
        number: number,
        upn: upn,
        name: name,
        busaldoCcyBr: busaldoCcyBr,
        id: id,
        status: status,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Returns a paged list of NostroLimitDto entities
   * @returns PagedModelEntityModelNostroLimitDto Paged list of NostroLimitDto entities
   * @throws ApiError
   */
  public static getAllNostroLimitForNostro({
    nostroPathId,
    page,
    size = 10,
    sort,
    search,
    valChf,
    nostro,
    id,
    validFrom,
    validTo,
  }: {
    nostroPathId: number;
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    search?: string;
    valChf?: number;
    nostro?: string;
    id?: number;
    validFrom?: string;
    validTo?: string;
  }): CancelablePromise<PagedModelEntityModelNostroLimitDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos/{nostroPathId}/nostro-limit",
      path: {
        nostroPathId: nostroPathId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
        search: search,
        valChf: valChf,
        nostro: nostro,
        id: id,
        validFrom: validFrom,
        validTo: validTo,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Returns a paged list of AccountDto entities
   * @returns PagedModelEntityModelAccountDto Paged list of AccountDto entities
   * @throws ApiError
   */
  public static getAllNostroAccounts({
    nostroPathId,
    page,
    size = 10,
    sort,
    hasNoLimits = false,
    search,
    numberFilter,
    number,
    accountLimits,
    nostro,
    currency,
    id,
    status,
  }: {
    nostroPathId: number;
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    hasNoLimits?: boolean;
    search?: string;
    numberFilter?: string;
    number?: string;
    accountLimits?: string;
    nostro?: string;
    currency?: string;
    id?: number;
    status?: string;
  }): CancelablePromise<PagedModelEntityModelAccountDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos/{nostroPathId}/account",
      path: {
        nostroPathId: nostroPathId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
        hasNoLimits: hasNoLimits,
        search: search,
        numberFilter: numberFilter,
        number: number,
        accountLimits: accountLimits,
        nostro: nostro,
        currency: currency,
        id: id,
        status: status,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Returns a paged list of AccountBalanceDto entities
   * @returns PagedModelEntityModelAccountBalanceDto Paged list of AccountBalanceDto entities
   * @throws ApiError
   */
  public static getAllAccountBalance({
    nostroPathId,
    page,
    size = 10,
    sort,
    onlyBreached = false,
    search,
    kstaValueCcy,
    hasLimits,
    kstaValueChfBreach,
    description,
    busaldoDate,
    busaldoValueChf,
    kstaValueCcyBreach,
    nostroId,
    accountId,
    number,
    busaldoValueCcy,
    busaldoValueCcyBreach,
    currency,
    id,
    kstaValueChf,
    cobDate,
    busaldoValueChfBreach,
  }: {
    nostroPathId: number;
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    onlyBreached?: boolean;
    search?: string;
    kstaValueCcy?: number;
    hasLimits?: boolean;
    kstaValueChfBreach?: boolean;
    description?: string;
    busaldoDate?: string;
    busaldoValueChf?: number;
    kstaValueCcyBreach?: boolean;
    nostroId?: number;
    accountId?: number;
    number?: string;
    busaldoValueCcy?: number;
    busaldoValueCcyBreach?: boolean;
    currency?: string;
    id?: number;
    kstaValueChf?: number;
    cobDate?: string;
    busaldoValueChfBreach?: boolean;
  }): CancelablePromise<PagedModelEntityModelAccountBalanceDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos/{nostroPathId}/acc-bal",
      path: {
        nostroPathId: nostroPathId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
        onlyBreached: onlyBreached,
        search: search,
        kstaValueCcy: kstaValueCcy,
        hasLimits: hasLimits,
        kstaValueChfBreach: kstaValueChfBreach,
        description: description,
        busaldoDate: busaldoDate,
        busaldoValueChf: busaldoValueChf,
        kstaValueCcyBreach: kstaValueCcyBreach,
        nostroId: nostroId,
        accountId: accountId,
        number: number,
        busaldoValueCcy: busaldoValueCcy,
        busaldoValueCcyBreach: busaldoValueCcyBreach,
        currency: currency,
        id: id,
        kstaValueChf: kstaValueChf,
        cobDate: cobDate,
        busaldoValueChfBreach: busaldoValueChfBreach,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Returns a paged list of AccountBalanceDto entities
   * @returns PagedModelEntityModelAccountBalanceDto Paged list of AccountBalanceDto entities
   * @throws ApiError
   */
  public static getAllAccountBalanceHistory({
    nostroPathId,
    page,
    size = 10,
    sort,
    onlyBreached = false,
    search,
    kstaValueCcy,
    hasLimits,
    kstaValueChfBreach,
    description,
    busaldoDate,
    busaldoValueChf,
    kstaValueCcyBreach,
    nostroId,
    accountId,
    number,
    busaldoValueCcy,
    busaldoValueCcyBreach,
    currency,
    id,
    kstaValueChf,
    cobDate,
    busaldoValueChfBreach,
  }: {
    nostroPathId: number;
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    onlyBreached?: boolean;
    search?: string;
    kstaValueCcy?: number;
    hasLimits?: boolean;
    kstaValueChfBreach?: boolean;
    description?: string;
    busaldoDate?: string;
    busaldoValueChf?: number;
    kstaValueCcyBreach?: boolean;
    nostroId?: number;
    accountId?: number;
    number?: string;
    busaldoValueCcy?: number;
    busaldoValueCcyBreach?: boolean;
    currency?: string;
    id?: number;
    kstaValueChf?: number;
    cobDate?: string;
    busaldoValueChfBreach?: boolean;
  }): CancelablePromise<PagedModelEntityModelAccountBalanceDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos/{nostroPathId}/acc-bal-hst",
      path: {
        nostroPathId: nostroPathId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
        onlyBreached: onlyBreached,
        search: search,
        kstaValueCcy: kstaValueCcy,
        hasLimits: hasLimits,
        kstaValueChfBreach: kstaValueChfBreach,
        description: description,
        busaldoDate: busaldoDate,
        busaldoValueChf: busaldoValueChf,
        kstaValueCcyBreach: kstaValueCcyBreach,
        nostroId: nostroId,
        accountId: accountId,
        number: number,
        busaldoValueCcy: busaldoValueCcy,
        busaldoValueCcyBreach: busaldoValueCcyBreach,
        currency: currency,
        id: id,
        kstaValueChf: kstaValueChf,
        cobDate: cobDate,
        busaldoValueChfBreach: busaldoValueChfBreach,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Returns a paged list of OtherNostroViewDto entities
   * @returns PagedModelEntityModelOtherNostroViewDto Paged list of OtherNostroViewDto entities
   * @throws ApiError
   */
  public static getOtherNostroViews(): CancelablePromise<PagedModelEntityModelOtherNostroViewDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos/view/other-views",
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Returns a paged list of NostroVsAccountBalanceDto entities
   * @returns PagedModelEntityModelNostroVsAccountBalanceDto Paged list of NostroVsAccountBalanceDto entities
   * @throws ApiError
   */
  public static getAllNostroVsAccountBalance({
    page,
    size = 10,
    sort,
    search,
    number,
    maxCobDate,
    riskFraud,
    name,
    count,
    minCobDate,
    id,
    status,
  }: {
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    search?: string;
    number?: string;
    maxCobDate?: string;
    riskFraud?: string;
    name?: string;
    count?: number;
    minCobDate?: string;
    id?: number;
    status?: string;
  }): CancelablePromise<PagedModelEntityModelNostroVsAccountBalanceDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos/view/nos-vs-acc-bal",
      query: {
        page: page,
        size: size,
        sort: sort,
        search: search,
        number: number,
        maxCobDate: maxCobDate,
        riskFraud: riskFraud,
        name: name,
        count: count,
        minCobDate: minCobDate,
        id: id,
        status: status,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Returns a paged list of NostroStagingUpdateDto entities
   * @returns PagedModelEntityModelNostroStagingUpdateDto Paged list of NostroStagingUpdateDto entities
   * @throws ApiError
   */
  public static getAllNostroToUpdate({
    page,
    size = 10,
    sort,
    search,
    addrL1,
    addrL2,
    stagedName,
    rmId,
    stagedRmNumber,
    stagedRmId,
    rmNumber,
    addrL4,
    stagedAddrL1,
    number,
    stagedAddrL2,
    stagedAddrL4,
    statusId,
    nostroStagingId,
    name,
    id,
  }: {
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    search?: string;
    addrL1?: string;
    addrL2?: string;
    stagedName?: string;
    rmId?: number;
    stagedRmNumber?: string;
    stagedRmId?: number;
    rmNumber?: string;
    addrL4?: string;
    stagedAddrL1?: string;
    number?: string;
    stagedAddrL2?: string;
    stagedAddrL4?: string;
    statusId?: number;
    nostroStagingId?: number;
    name?: string;
    id?: number;
  }): CancelablePromise<PagedModelEntityModelNostroStagingUpdateDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos/view/nos-to-upd",
      query: {
        page: page,
        size: size,
        sort: sort,
        search: search,
        addrL1: addrL1,
        addrL2: addrL2,
        stagedName: stagedName,
        rmId: rmId,
        stagedRmNumber: stagedRmNumber,
        stagedRmId: stagedRmId,
        rmNumber: rmNumber,
        addrL4: addrL4,
        stagedAddrL1: stagedAddrL1,
        number: number,
        stagedAddrL2: stagedAddrL2,
        stagedAddrL4: stagedAddrL4,
        statusId: statusId,
        nostroStagingId: nostroStagingId,
        name: name,
        id: id,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Returns a paged list of NostroStagingDeleteDto entities
   * @returns PagedModelEntityModelNostroStagingDeleteDto Paged list of NostroStagingDeleteDto entities
   * @throws ApiError
   */
  public static getAllNostroToDelete({
    page,
    size = 10,
    sort,
    search,
    number,
    statusId,
    nostroCategoryText,
    nostroCategoryId,
    riskFraudCode,
    name,
    id,
    newStatusCode,
    riskFraudId,
    statusCode,
  }: {
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    search?: string;
    number?: string;
    statusId?: number;
    nostroCategoryText?: string;
    nostroCategoryId?: number;
    riskFraudCode?: string;
    name?: string;
    id?: number;
    newStatusCode?: string;
    riskFraudId?: number;
    statusCode?: string;
  }): CancelablePromise<PagedModelEntityModelNostroStagingDeleteDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos/view/nos-to-del",
      query: {
        page: page,
        size: size,
        sort: sort,
        search: search,
        number: number,
        statusId: statusId,
        nostroCategoryText: nostroCategoryText,
        nostroCategoryId: nostroCategoryId,
        riskFraudCode: riskFraudCode,
        name: name,
        id: id,
        newStatusCode: newStatusCode,
        riskFraudId: riskFraudId,
        statusCode: statusCode,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Returns a paged list of AccountBalanceCobDto entities
   * @returns PagedModelEntityModelAccountBalanceCobDto Paged list of AccountBalanceCobDto entities
   * @throws ApiError
   */
  public static getAllAccountBalanceCob({
    page,
    size = 10,
    sort,
    search,
    count,
    id,
    cobDate,
  }: {
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    search?: string;
    count?: number;
    id?: number;
    cobDate?: string;
  }): CancelablePromise<PagedModelEntityModelAccountBalanceCobDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos/view/cob-dt-vs-acc-bal",
      query: {
        page: page,
        size: size,
        sort: sort,
        search: search,
        count: count,
        id: id,
        cobDate: cobDate,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Returns a paged list of AccountBalanceMissingCobDto entities
   * @returns PagedModelEntityModelAccountBalanceMissingCobDto Paged list of AccountBalanceMissingCobDto entities
   * @throws ApiError
   */
  public static getAllAccountBalanceMissingCob({
    page,
    size = 10,
    sort,
    search,
    count,
    weekday,
    id,
    cobDate,
  }: {
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    search?: string;
    count?: number;
    weekday?: string;
    id?: number;
    cobDate?: string;
  }): CancelablePromise<PagedModelEntityModelAccountBalanceMissingCobDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos/view/cob-dt-missing",
      query: {
        page: page,
        size: size,
        sort: sort,
        search: search,
        count: count,
        weekday: weekday,
        id: id,
        cobDate: cobDate,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Returns a paged list of AccountBalanceLimitBreachDto entities
   * @returns PagedModelEntityModelAccountBalanceLimitBreachDto Paged list of AccountBalanceLimitBreachDto entities
   * @throws ApiError
   */
  public static getAllAccountBalanceLimitBreach({
    page,
    size = 10,
    sort,
    search,
    number,
    riskFraud,
    kstaCcy,
    busaldoValueCcy,
    name,
    count,
    id,
    kstaChf,
    busaldoValueChf,
    status,
  }: {
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    search?: string;
    number?: string;
    riskFraud?: string;
    kstaCcy?: number;
    busaldoValueCcy?: number;
    name?: string;
    count?: number;
    id?: number;
    kstaChf?: number;
    busaldoValueChf?: number;
    status?: string;
  }): CancelablePromise<PagedModelEntityModelAccountBalanceLimitBreachDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos/view/acc-bal-lim-br",
      query: {
        page: page,
        size: size,
        sort: sort,
        search: search,
        number: number,
        riskFraud: riskFraud,
        kstaCcy: kstaCcy,
        busaldoValueCcy: busaldoValueCcy,
        name: name,
        count: count,
        id: id,
        kstaChf: kstaChf,
        busaldoValueChf: busaldoValueChf,
        status: status,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }
}
