import { EntityModelNostroCommentDto } from "../../openapi";
import { createSelectedItemSlice } from "../selected-item-slice";

const SelectedNostroCommentSlice =
  createSelectedItemSlice<EntityModelNostroCommentDto>("selectedNostroComment");

export const { setSelectedItem: setSelectedNostroComment } =
  SelectedNostroCommentSlice.actions;

export default SelectedNostroCommentSlice.reducer;
