import { EntityModelNostroVsAccountBalanceDto } from "../../openapi";
import { createSelectedItemSlice } from "../selected-item-slice";

// Create specific slices for different types of selected items
const SelectedNostrovsAccountSlice =
  createSelectedItemSlice<EntityModelNostroVsAccountBalanceDto>(
    "selectedNostroVsAccount"
  );

export const { setSelectedItem: setSelectedNostroVsAccount } =
  SelectedNostrovsAccountSlice.actions;

export default SelectedNostrovsAccountSlice.reducer;
