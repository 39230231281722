import { EntityModelNostroLimitDto } from "../../openapi";
import { createSelectedItemSlice } from "../selected-item-slice";

const SelectedNostroNostroLimitLogSlice =
  createSelectedItemSlice<EntityModelNostroLimitDto>(
    "selectedNostroNostroLimitLog"
  );

export const { setSelectedItem: setSelectedNostroNostroLimitLog } =
  SelectedNostroNostroLimitLogSlice.actions;

export default SelectedNostroNostroLimitLogSlice.reducer;
