import { ZfSearchField, ZfTable, ZfTextButton } from "@ccx/zafire-react";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../common/ZfTable/zftable-configuration";
import React, { useCallback, useEffect, useRef, useState } from "react";
import authorizedCall from "../../utils/authorizedCallUtils";
import {
  EntityModelAccountDto,
  EntityModelAccountLimitDto,
  EntityModelStatusDto,
  NostroControllerService,
} from "../../openapi";
import { useNavigate } from "react-router-dom";
import { AppError, appStateActions } from "../../store/appstate-slice";
import { StoreSlices, useAppDispatch, useAppSelector } from "../../store/store";
import Spinner from "../common/Spinner";
import { setSelectedNostroAccountLimit } from "../../store/select-item-slice-impl/selected-item-slice-nostro-acc-limit";
import {
  importCellFormatter,
  customNumericEditor,
} from "../common/ZfTable/zftable-cell-editors";

const cols = [
  "kstaValueCcyLimitLow",
  "kstaValueCcyLimitHigh",
  "busaldoValueCcyLimitLow",
  "busaldoValueCcyLimitHigh",
  "statusText",
  "remark",
];

export const AccountViewEditLimits: React.FC<{
  nosId: number;
}> = (props) => {
  const navigate = useNavigate();

  const searchFilter = useRef<HTMLZfSearchFieldElement>(null);
  const dataTable = useRef<HTMLZfTableElement>(null);
  const [selected, setSelected] = useState<any>(undefined);

  const selectedRedux = useAppSelector(
    (state) => state.selectedNostroAccountLimitSlice.selected
  );

  const [limitFetching, setLimitFetching] = useState<boolean>(false);

  const [editMode, setEditMode] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const statuses = useAppSelector((state) => state.comboBoxSlice.statuses);

  const [nostroAccountCount, setNostroAccountCount] = useState(0);

  // useEffect to log all states on each change
  useEffect(() => {
    console.log("Component state snapshot:", {
      selected,
      statuses,
      limitFetching,
      editMode,
      nosId: props.nosId,
    });
  }, [selected, statuses, limitFetching, editMode, props.nosId]);

  useEffect(() => {
    //if (!statuses) return;

    //const getAccounts =
    async function getAllNostroAccounts(): Promise<
      Array<EntityModelAccountDto>
    > {
      const apiParams = {
        nostroPathId: Number(props.nosId!),
        hasNoLimits: true,
      };

      return authorizedCall(
        NostroControllerService.getAllNostroAccounts,
        apiParams
        //true
      ).then((dto: EntityModelAccountDto[]) => {
        if (dto.length === 0) {
          const err: AppError = {
            code: -1,
            message: "No account available for this nostro.",
          };
          dispatch(appStateActions.error(err));
        }
        return dto;
      });
    }

    getAllNostroAccounts().then((list) => {
      setNostroAccountCount(list.length);
    });

    const dataTableApiCall = async (url: any, config: any, params: any) => {
      setLimitFetching(true);
      const sortArray: Array<string> =
        params.sort.length > 0
          ? [`${params.sort[0].field},${params.sort[0].dir}`]
          : [];
      let apiParams: Parameters<
        typeof NostroControllerService.getAllAccountLimitForNostro
      >[0] = {
        nostroPathId: props.nosId,
        page: params.page,
        size: params.size,
        sort: sortArray,
      };

      if (searchFilter.current?.value) {
        apiParams = { ...apiParams, search: searchFilter.current?.value };
      }

      return authorizedCall(
        NostroControllerService.getAllAccountLimitForNostro,
        apiParams,
        true
      )
        .then((resp) => {
          return resp;
        })
        .finally(() => {
          //setLimitFetching(false);
        });
    };

    const defaultOptions = zfTableDefaultOptions();

    const TABLE_COLUMNS = [
      {
        formatter: "zfRadioRowSelection",
        hozAlign: "center",
        headerSort: false,
        width: 50,
      },
      { title: "ID", field: "id", maxWidth: 70 },
      { title: "Account<br/>Number", field: "accountNumber", maxWidth: 160 },
      { title: "CCY", field: "accountCurrencyLabel", maxWidth: 80 },
      { title: "Valid From", field: "validFrom", maxWidth: 130 },
      { title: "Valid To", field: "validTo", maxWidth: 130 },
      {
        title: "KSTA VAL CCY<br/>Low limit",
        field: "kstaValueCcyLimitLow",
        headerHozAlign: "right",
        hozAlign: "right",
        formatter: importCellFormatter,
        editor: customNumericEditor,
        editable: false,
      },
      {
        title: "KSTA VAL CCY<br/>High limit",
        field: "kstaValueCcyLimitHigh",
        headerHozAlign: "right",
        hozAlign: "right",
        formatter: importCellFormatter,
        editor: customNumericEditor,
        editable: false,
      },
      {
        title: "BuSaldo VAL CCY<br/>Low Limit",
        field: "busaldoValueCcyLimitLow",
        headerHozAlign: "right",
        hozAlign: "right",
        formatter: importCellFormatter,
        editor: customNumericEditor,
        editable: false,
      },
      {
        title: "BuSaldo VAL CCY<br/>High Limit",
        field: "busaldoValueCcyLimitHigh",
        headerHozAlign: "right",
        hozAlign: "right",
        formatter: importCellFormatter,
        editor: customNumericEditor,
        editable: false,
      },
      {
        title: "Status",
        field: "statusText",
        editor: "list",
        editorParams: {
          values: statuses && statuses.map((elem: any) => elem.statusText),
        },
        editable: false,
        formatter: (cell: any) => formatStatusCell(cell.getValue()),
        maxWidth: 130,
      },
      {
        title: "Remarks",
        field: "remark",
        maxWidth: 150,
        editable: false,
        editor: "input",
      },
    ];

    const actionColumns: [] = [];

    dataTable.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableApiCall,
      selectable: 1,
    };
  }, [props.nosId, statuses, dispatch]);

  const setEditableColumn = useCallback(
    () =>
      selected
        ?.getCells()
        .filter((cell: any) => cols.includes(cell.getField()))
        .forEach((cell: any) =>
          cell.getColumn().updateDefinition({
            ...cell.getColumn().getDefinition(),
            editable: (cell: any) =>
              editMode && cell.getRow().getIndex() === selected.getIndex(),
          })
        ),
    [selected, editMode]
  );

  useEffect(() => setEditableColumn(), [setEditableColumn]);

  function refreshTable(event: any) {
    dataTable.current!.options = { ...dataTable.current!.options };
  }

  const rowSelectedCallBack = useCallback(
    (row: CustomEvent) => {
      setSelected(row);
      if (editMode) {
        setEditMode(false);
      }
    },
    [editMode]
  );

  const clickEditHandler = () => {
    setEditMode(true);
  };

  const clickCancelHandler = () => {
    setEditMode(false);
  };

  const clickSaveHandler = () => {
    console.log(selected.getData());
    const limit: EntityModelAccountLimitDto = selected.getData();
    setLimitFetching(true);

    const status: EntityModelStatusDto | undefined = statuses?.find(
      (value: EntityModelStatusDto) => value.statusText === limit.statusText
    );

    authorizedCall(NostroControllerService.updateAccountLimit, {
      nostroPathId: props.nosId,
      limitId: limit.id,
      requestBody: {
        kstaValueCcyLimitLow: Number(limit.kstaValueCcyLimitLow),
        kstaValueCcyLimitHigh: Number(limit.kstaValueCcyLimitHigh),
        busaldoValueCcyLimitLow: Number(limit.busaldoValueCcyLimitLow),
        busaldoValueCcyLimitHigh: Number(limit.busaldoValueCcyLimitHigh),
        remark: limit.remark,
        status: Number(status!.statusId),
      },
    })
      .then(() => {
        setEditMode(false);
        refreshTable("");
      })
      .catch((error) => {
        const err: AppError = {
          code: error.status,
          message: error.error?.toString(),
        };
        dispatch(appStateActions.error(err));
      })
      .finally(() => {
        setLimitFetching(false);
      });
  };

  const tableBuiltHandlerCallback = useCallback(
    (event: any) => {
      tableBuiltHandler(
        event,
        setLimitFetching,
        undefined,
        StoreSlices.selectedNostroAccountLimitSlice,
        setSelectedNostroAccountLimit,
        rowSelectedCallBack
      );
    },
    [rowSelectedCallBack]
  );

  /*console.log(
    "RENDER - AccountViewEditLimits [limitFetching:",
    limitFetching,
    "]"
  );*/

  function formatStatusCell(cellValue: string) {
    const color =
      {
        ok: "green",
        archived: "green",
        check: "yellow",
        delete: "red",
        new: "blue",
      }[cellValue] || "neutral";

    return `<zf-pill type="solid" color=${color}>${cellValue}</zf-pill>`;
  }

  return (
    <>
      <div className="flex flex-row-reverse">
        <div className="basis-1/4 flex flex-row-reverse">
          <ZfTextButton
            disabled={nostroAccountCount === 0}
            hierarchy="primary"
            onClick={() => navigate(`/acc-limit-edit/${props.nosId}/new`)}
            size="small"
          >
            Create
          </ZfTextButton>
          <ZfTextButton
            disabled={selectedRedux === null}
            onClick={(editMode && clickSaveHandler) || clickEditHandler}
            hierarchy="secondary"
            className="mr-1"
            size="small"
          >
            {(editMode && "Save") || "Edit"}
          </ZfTextButton>
          {editMode && (
            <ZfTextButton
              onClick={clickCancelHandler}
              hierarchy="secondary"
              className="mr-1"
              size="small"
            >
              Cancel
            </ZfTextButton>
          )}
        </div>
        <div className="basis-1/4 mr-12">
          <ZfSearchField
            placeholder="Search ..."
            onInputChange={(e) => {
              refreshTable(e);
            }}
            ref={searchFilter}
          ></ZfSearchField>
        </div>
      </div>

      <Spinner fetching={limitFetching} />

      <ZfTable
        ref={dataTable}
        onTableBuilt={(event) => tableBuiltHandlerCallback(event)}
        //onTableRowSelected={rowSelectedCallBack}
        className="mt-5"
        data-testid="acc-lim-data-table"
        style={{
          pointerEvents: limitFetching && "none",
          opacity: limitFetching && 0.7,
        }}
      ></ZfTable>
      <div className="text-right mt-3">
        <i>* Select row clicking radio button or a column between ID and CCY</i>
      </div>
    </>
  );
};
