import { FC, PropsWithChildren } from "react";
import { authorize, refresh, toLoginPage } from "../../store/auth-utils";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { Authentication, authActions } from "../../store/auth-slice";

const Oidc: FC<PropsWithChildren> = (props) => {
  const authError = useAppSelector((state) => state.authSlice.authError);
  const authTokens = useAppSelector((state) => state.authSlice.authTokens);
  const isLoading = useAppSelector((state) => state.authSlice.isLoading);
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector((state) => state.authSlice.isAuth);

  /**
   * check
   */
  const location = window.location.href;
  // console.log('isAuth:', isAuth, 'isLoading:', isLoading, 'location:', location);
  console.log("isAuth:", isAuth, "location:", location);
  //alert(location);
  /**
   * If no access token in local storage
   * If it isn't in fetch
   */

  //console.log("###LOCATION###", location);

  //useEffect(() => {
  if (
    authError <= 0 &&
    !isAuth &&
    !isLoading &&
    location.includes("authorize?code=")
  ) {
    //alert("LOCATION: " + location);
    dispatch(authActions.loading(true));
    authorize()
      .then((resp) => {
        //dispatch(authActions.loading(false));
        console.log("authorize response:", resp.url, resp.status, resp.body);
        if (!resp?.ok) {
          alert("authorize error");
          throw Error("authorize error");
        } else {
          resp
            ?.json()
            .then((content) => {
              const authentication: Authentication = {
                accessToken: content.access_token,
                idToken: content.id_token,
                refreshToken: content.refresh_token,
              };
              dispatch(authActions.signIn(authentication));
            })
            .catch((error) => {
              console.error(error.message);
              dispatch(authActions.authError(error.code));
            })
            .finally(() => {
              dispatch(authActions.loading(false));
              return;
            });
        }
      })
      .catch((error) => {
        if (error.message === "authorize error") {
          toLoginPage();
        } else {
          console.error("catched error:", error);
          throw error;
        }
        return;
      });

    /**
     * try to use refresh token to obtain new access token
     */
  } else if (isAuth && authError === 401) {
    if (!authTokens?.refreshToken) {
      dispatch(authActions.signOut());
    } else {
      refresh(authTokens?.refreshToken).then((resp) => {
        //alert('refresh resp:\n ' + resp.status + '\n' + resp.body);
        if (resp.status === 400) {
          //dispatch(authActions.signOut());
          //return;
        }
        resp
          ?.json()
          .then((content) => {
            //console.log('response.json() :' + JSON.stringify(content));
            const authentication: Authentication = {
              accessToken: content.access_token,
              idToken: content.id_token,
            };
            dispatch(authActions.signIn(authentication));
            //dispatch(authActions.loading(false));
            dispatch(authActions.authError(-1));
          })
          .finally(() => {
            dispatch(authActions.loading(false));
          });
      });

      //dispatch(authActions.loading(true));
    }
  } else if (location.includes("authorize?error=invalid_resource")) {
    throw new Error("Adfs Error: " + location);
  } else if (!isAuth && !isLoading) {
    toLoginPage();
  }
  //}, []);

  console.log("RENDER - Oidc.tsx");
  return (
    <>
      {isAuth ? (
        props.children
      ) : (
        <>
          <h2>Not Authenticated</h2>
        </>
      )}
    </>
  );
};

export default Oidc;
