/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedModelEntityModelAuditDto } from "../models/PagedModelEntityModelAuditDto";
import type { PagedModelEntityModelRelationshipManagerDto } from "../models/PagedModelEntityModelRelationshipManagerDto";
import type { PagedModelEntityModelRMStagingUpdateDto } from "../models/PagedModelEntityModelRMStagingUpdateDto";
import type { RelationshipManagerUpdateDto } from "../models/RelationshipManagerUpdateDto";
import type { ReportResponse } from "../models/ReportResponse";
import type { RMUpdateSelectionDto } from "../models/RMUpdateSelectionDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class RelationshipManagerControllerService {
  /**
   * Updates all selected RelationshipManager with data coming from staging table
   * @returns ReportResponse Selected Relationship Managers updated
   * @throws ApiError
   */
  public static updateSelectedRelationshipManager({
    requestBody,
  }: {
    requestBody: RMUpdateSelectionDto;
  }): CancelablePromise<ReportResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/rm/update",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        403: `Unauthorized user`,
        404: `Record not found`,
      },
    });
  }

  /**
   * Updates a specific Relationship Manager to find using the id sent
   * @returns PagedModelEntityModelRelationshipManagerDto Record updated
   * @throws ApiError
   */
  public static updateRelationshipManager({
    relationshipManagerPathId,
    requestBody,
  }: {
    relationshipManagerPathId: number;
    requestBody: RelationshipManagerUpdateDto;
  }): CancelablePromise<PagedModelEntityModelRelationshipManagerDto> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/rm/{relationshipManagerPathId}",
      path: {
        relationshipManagerPathId: relationshipManagerPathId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        403: `Unauthorized user`,
        404: `Record not found`,
      },
    });
  }

  /**
   * Returns a paged list of NostroCategoryDto entities
   * @returns PagedModelEntityModelRelationshipManagerDto Paged list of RelationshipManagerDto entities
   * @throws ApiError
   */
  public static getAllRelationshipManagers({
    page,
    size = 10,
    sort,
    search,
    number,
    upn,
    address,
    emailSentBy,
    phone,
    orgUnit,
    name,
    emailSentAt,
    id,
    status,
  }: {
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    search?: string;
    number?: string;
    upn?: string;
    address?: string;
    emailSentBy?: string;
    phone?: string;
    orgUnit?: string;
    name?: string;
    emailSentAt?: string;
    id?: number;
    status?: string;
  }): CancelablePromise<PagedModelEntityModelRelationshipManagerDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/rm",
      query: {
        page: page,
        size: size,
        sort: sort,
        search: search,
        number: number,
        upn: upn,
        address: address,
        emailSentBy: emailSentBy,
        phone: phone,
        orgUnit: orgUnit,
        name: name,
        emailSentAt: emailSentAt,
        id: id,
        status: status,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Returns a paged list of RMStagingUpdateDto entities
   * @returns PagedModelEntityModelRMStagingUpdateDto Paged list of RMStagingUpdateDto entities
   * @throws ApiError
   */
  public static getAllRelationshipManagerToUpdate({
    page,
    size = 10,
    sort,
    search,
    stagedName,
    address,
    orgUnit,
    stagedUpn,
    stagedOrgUnit,
    number,
    upn,
    rmStagingId,
    stagedPhone,
    statusId,
    phone,
    name,
    stagedAddress,
    id,
  }: {
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    search?: string;
    stagedName?: string;
    address?: string;
    orgUnit?: string;
    stagedUpn?: string;
    stagedOrgUnit?: string;
    number?: string;
    upn?: string;
    rmStagingId?: number;
    stagedPhone?: string;
    statusId?: number;
    phone?: string;
    name?: string;
    stagedAddress?: string;
    id?: number;
  }): CancelablePromise<PagedModelEntityModelRMStagingUpdateDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/rm/view/rm-to-upd",
      query: {
        page: page,
        size: size,
        sort: sort,
        search: search,
        stagedName: stagedName,
        address: address,
        orgUnit: orgUnit,
        stagedUpn: stagedUpn,
        stagedOrgUnit: stagedOrgUnit,
        number: number,
        upn: upn,
        rmStagingId: rmStagingId,
        stagedPhone: stagedPhone,
        statusId: statusId,
        phone: phone,
        name: name,
        stagedAddress: stagedAddress,
        id: id,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Returns a paged list of Transaction entities
   * @returns PagedModelEntityModelAuditDto Paged list of AuditDto entities
   * @throws ApiError
   */
  public static getLastRelationshipManagersImport(): CancelablePromise<PagedModelEntityModelAuditDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/rm/last-import",
      errors: {
        403: `Unauthorized user`,
      },
    });
  }
}
