import { EntityModelStatusDto, StatusControllerService } from "../openapi";
import authorizedCall from "../utils/authorizedCallUtils";

async function fetchStatuses(): Promise<EntityModelStatusDto[]> {
  return authorizedCall(StatusControllerService.getAllStatuses, {
    page: 0,
    size: 100,
  }).then((statuses) => {
    localStorage.setItem("statuses", JSON.stringify(statuses));
    return statuses;
  });
}

export function getComboBoxStatuses(): Promise<EntityModelStatusDto[]> {
  const cachedStatuses = localStorage.getItem("statuses");

  if (cachedStatuses == null) {
    return fetchStatuses();
  }

  return Promise.resolve(JSON.parse(cachedStatuses) as EntityModelStatusDto[]);
}
