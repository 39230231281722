import { useCallback, useEffect, useRef, useState } from "react";
import {
  ZfIconButton,
  ZfSearchField,
  ZfTable,
  ZfTextButton,
} from "@ccx/zafire-react";
import { AuthenticatedLayout } from "../common/components/AuthenticatedLayout";
import { useNavigate } from "react-router-dom";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../common/ZfTable/zftable-configuration";
import authorizedCall from "../../utils/authorizedCallUtils";
import { NostroCategoryControllerService } from "../../openapi";
import Spinner from "../common/Spinner";
import { StoreSlices, useAppSelector } from "../../store/store";
import { setSelectedAccountCategory } from "../../store/select-item-slice-impl/selected-item-slice-account-category";

const AccountCategories = () => {
  const navigate = useNavigate();

  const dataTable = useRef<HTMLZfTableElement>(null);

  const [error, setError] = useState(false);

  const selected = useAppSelector(
    (state) => state.selectedAccountCategorySlice.selected
  );

  /*const [selected, setSelected] = useState<
    EntityModelNostroCategoryDto | undefined
  >(undefined);*/

  const [accountCatFetching, setAccountCatFetching] = useState<boolean>(true);

  //const [searchFilter, setSearchFilter] = useState('');
  const searchFilter = useRef<HTMLZfSearchFieldElement>(null);

  /**
   *
   * @param url the url of the request
   * @param config the ajaxConfig object
   * @param params the ajaxParams object
   * @returns
   */
  async function dataTableApiCall(url: any, config: any, params: any) {
    const sortArray: Array<string> =
      params.sort.length > 0
        ? [`${params.sort[0].field},${params.sort[0].dir}`]
        : [];

    let apiParams: Parameters<
      typeof NostroCategoryControllerService.getAllNostroCategories
    >[0] = {
      page: params.page,
      size: params.size,
      sort: sortArray,
    };

    if (searchFilter.current?.value) {
      apiParams = { ...apiParams, search: searchFilter.current?.value };
    }

    setAccountCatFetching(true);
    return authorizedCall(
      NostroCategoryControllerService.getAllNostroCategories,
      apiParams,
      true
    )
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error;
      })
      .finally(() => {
        setAccountCatFetching(false);
      });
  }

  /**
   *
   * First configuration after renderization
   */
  useEffect(() => {
    const defaultOptions = zfTableDefaultOptions(setError);

    const TABLE_COLUMNS = [
      {
        formatter: "zfRadioRowSelection",
        hozAlign: "center",
        headerSort: false,
        width: 50,
      },
      { title: "ID", field: "id", maxWidth: 80 },
      {
        title: "Code",
        field: "code",
        maxWidth: 200 /*, headerFilter: 'input'*/,
      },
      { title: "Text", field: "text", maxWidth: 300 },
      { title: "Comment", field: "comment" /*, headerFilter: 'input'*/ },
      {
        title: "Status",
        field: "statusText",
        formatter: (cell: any) => formatStatusCell(cell.getValue()),
        maxWidth: 100,
      },
      //{ title: "Created at", field: "createTs", maxWidth: 200 },
      //{ title: "Created by", field: "createUser", maxWidth: 120 },
    ];

    const actionColumns: [] = [];

    setError(false);
    dataTable.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableApiCall,
      selectable: 1,
    };
  }, []);

  /*function rowSelectionChanged(row: any) {
    //row - row component
    console.log("rowSelectionChanged: ", row);
    const selected: EntityModelNostroCategoryDto = row.detail.data[0];
    setSelected(selected);
  }*/

  const tableBuiltHandlerCallback = useCallback((event: any) => {
    tableBuiltHandler(
      event,
      setAccountCatFetching,
      undefined,
      StoreSlices.selectedAccountCategorySlice,
      setSelectedAccountCategory
    );
  }, []);

  function filteredSearch(event: any) {
    dataTable.current!.options = { ...dataTable.current!.options };
  }

  console.log(
    "RENDER - AccountCategories [accountCatFetching:",
    accountCatFetching,
    "]"
  );

  function formatStatusCell(cellValue: string) {
    const color =
      {
        ok: "green",
        arc: "green",
        chk: "yellow",
        del: "red",
        new: "blue",
      }[cellValue] || "neutral";

    return `<zf-pill type="solid" color=${color}>${cellValue}</zf-pill>`;
  }

  return (
    <AuthenticatedLayout
      breadcrumbList={[
        { name: "ACTION PANEL", route: "/" },
        { name: "View Nostro Category", route: "/acc-cat-view" },
      ]}
    >
      <div className="content p-4 bg-white">
        <div
          className="flex flex-row pb-6"
          style={{ borderBottom: "1px solid #ddd" }}
        >
          <div className="basis-1/2">
            <p
              className="heading--two text-lg"
              data-testid="view-acc-cat-pagetitle"
            >
              View Nostro Category
            </p>
          </div>
          <div className="basis-1/4 text-sm">
            <ZfSearchField
              placeholder="Search ..."
              onInputChange={(e) => {
                filteredSearch(e);
              }}
              ref={searchFilter}
            ></ZfSearchField>
          </div>
          <div className="basis-1/4 flex flex-row-reverse">
            <ZfTextButton
              onClick={() => navigate(`/acc-cat-view/new`)}
              size="small"
            >
              Create
            </ZfTextButton>
            <ZfTextButton
              disabled={selected === null}
              onClick={() => navigate(`/acc-cat-view/${selected?.id}`)}
              hierarchy="secondary"
              className="mr-1"
              size="small"
            >
              Edit
            </ZfTextButton>
            <ZfIconButton
              hierarchy="secondary"
              icon="arrow_back"
              className="mr-1"
              onClick={() => navigate(`/`)}
              size="small"
            ></ZfIconButton>
          </div>
        </div>
        {error && <p className="error">Error in loading data</p>}

        {accountCatFetching && <Spinner fetching={accountCatFetching} />}

        <ZfTable
          ref={dataTable}
          onTableBuilt={(event) => tableBuiltHandlerCallback(event)}
          //onTableRowSelectionChanged={rowSelectionChanged}
          className="mt-5"
          style={{
            pointerEvents: accountCatFetching && "none",
            opacity: accountCatFetching && 0.7,
          }}
          data-testid="ac-data-table"
        ></ZfTable>
      </div>
    </AuthenticatedLayout>
  );
};

export default AccountCategories;
