import { ZfLoadingSpinner } from "@ccx/zafire-react";
import { FC } from "react";

const Spinner: FC<{ fetching: boolean }> = (props) => {
  return (
    <>
      {props.fetching && (
        <div
          id="spinnerContainer"
          data-testid="spinner"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <ZfLoadingSpinner size="large" color="primary" type="infinite" />
        </div>
      )}
    </>
  );
};

export default Spinner;
